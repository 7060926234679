import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { format, parseISO } from "date-fns";
import { Button } from "@/components/ui/button";
import { Download, Loader2, XCircle } from "lucide-react";
import { trpc } from "@/lib/trpc";
import { useAppSelector } from "@/redux/store";
import { CerevLogo } from "../../icons/CerevLogo";
import AppButton from "../../components/AppButton";

interface WorkOrderData {
  // timestamp: Date;
  "Doc No": string;
  "Doc Date": string;
  "Debtor Name": string;
  "Detail Description": string;
  "Total (inc)": string;
  "Labour Costing (RM)": string;
  "Part Costing (RM)": string;
  "Milleage Costing (RM)": string;
  Profit: string;
  PIC: string;
}

export default function CustomExcelScreen() {
  const activeProject = useAppSelector((state) => state.root.activeProject);
  const [isLoading, setIsLoading] = useState(false);

  const {
    data: workOrdersData,
    isLoading: workOrdersIsLoading,
    isError: workOrdersIsError,
  } = trpc.customCsv.getIPMpartsAndAccessoriesCosting.useQuery(
    {
      projectId: activeProject?.id?.toString() ?? "",
    },
    {
      enabled: !!activeProject?.id,
    }
  );

  const handleDownload = async () => {
    if (!workOrdersData) return;
    setIsLoading(true);

    try {
      // Create a new workbook
      const workbook = XLSX.utils.book_new();

      // Group data by month
      const groupedByMonth = workOrdersData.reduce<
        Record<string, WorkOrderData[]>
      >((acc, item) => {
        const {timestamp, ...remainingWorkOrder} = item;
        const monthYear = format(item.timestamp, "MMMM yyyy");

        if (!acc[monthYear]) {
          acc[monthYear] = [];
        }

        acc[monthYear].push(remainingWorkOrder);
        return acc;
      }, {});

      // Create sheets for each month
      Object.entries(groupedByMonth).forEach(([monthYear, data]) => {
        // Create worksheet from data
        const worksheet = XLSX.utils.json_to_sheet(data);

        // Set column widths
        const columnWidths = [
          { wch: 15 }, // Doc No
          { wch: 12 }, // Doc Date
          { wch: 30 }, // Debtor Name
          { wch: 40 }, // Detail Description
          { wch: 15 }, // Total
          { wch: 15 }, // Labour Costing
          { wch: 15 }, // Part Costing
          { wch: 15 }, // Milleage Costing
          { wch: 15 }, // Profit
          { wch: 30 }, // PIC
        ];
        worksheet["!cols"] = columnWidths;

        // Add the worksheet to workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, monthYear);
      });

      // Generate summary sheet
      const summaryData = Object.entries(groupedByMonth).map(
        ([monthYear, data]) => {
          const totalProfit = data.reduce(
            (sum, item) => sum + parseFloat(item.Profit),
            0
          );
          const totalRevenue = data.reduce(
            (sum, item) => sum + parseFloat(item["Total (inc)"]),
            0
          );
          const totalLabour = data.reduce(
            (sum, item) => sum + parseFloat(item["Labour Costing (RM)"]),
            0
          );
          const totalParts = data.reduce(
            (sum, item) => sum + parseFloat(item["Part Costing (RM)"]),
            0
          );
          const totalMileage = data.reduce(
            (sum, item) => sum + parseFloat(item["Milleage Costing (RM)"]),
            0
          );

          return {
            Month: monthYear,
            "Total Revenue": totalRevenue.toFixed(2),
            "Total Labour Cost": totalLabour.toFixed(2),
            "Total Parts Cost": totalParts.toFixed(2),
            "Total Mileage Cost": totalMileage.toFixed(2),
            "Total Profit": totalProfit.toFixed(2),
            "Work Orders Count": data.length,
          };
        }
      );

      const summarySheet = XLSX.utils.json_to_sheet(summaryData);
      summarySheet["!cols"] = [
        { wch: 15 }, // Month
        { wch: 15 }, // Total Revenue
        { wch: 15 }, // Total Labour
        { wch: 15 }, // Total Parts
        { wch: 15 }, // Total Mileage
        { wch: 15 }, // Total Profit
        { wch: 15 }, // Count
      ];
      XLSX.utils.book_append_sheet(workbook, summarySheet, "Summary");

      // Save the file
      XLSX.writeFile(
        workbook,
        `Summary_Work_Orders_Costing_${format(
          new Date(),
          "yyyy-MM-dd"
        )}.xlsx`
      );
    } catch (error) {
      console.error("Error generating Excel file:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (workOrdersIsLoading) {
    return (
      <div className="h-screen w-screen flex flex-col items-center justify-center">
        <div className="h-20 w-20 mb-2">
          <CerevLogo />
        </div>
        <p className="font-bold text-2xl mb-6">Cerev</p>
        <div className="flex flex-col items-center justify-center gap-4">
          <Loader2 className="text-primary-900 animate-spin w-12 h-12" />
          <p className="text-slate-900 font-bold">Fetching your data...</p>
          <p className="text-slate-500 text-sm">
            This process may take up to 5 - 10 mins depending on size of data...
          </p>
        </div>
      </div>
    );
  }

  if (workOrdersIsError) {
    return (
      <div className="h-screen w-screen flex flex-col items-center justify-center">
        <div className="h-20 w-20 mb-2">
          <CerevLogo />
        </div>
        <p className="font-bold text-2xl mb-6">Cerev</p>
        <div className="flex flex-col items-center justify-center gap-4">
          <XCircle className="text-red-500  w-12 h-12" />
          <p className="text-red-500 font-bold">Something went wrong</p>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center gap-4">
      <div className="h-20 w-20 mb-2">
        <CerevLogo />
      </div>
      <p className="font-bold text-2xl mb-6">Cerev</p>
      <AppButton
        label="Download"
        variant="outline"
        icon={<Download />}
        onClick={handleDownload}
        disabled={isLoading || !workOrdersData}
      />
      <p className="text-slate-900 font-bold">Your data is ready to download</p>
    </div>
  );
}
