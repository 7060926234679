import {
  AlignLeft,
  Ban,
  Building,
  ChevronRight,
  Computer,
  Link,
  Mail,
  MapPin,
  Pencil,
  Phone,
  Star,
  Tag,
  User,
} from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { useWorkRequestControllerGetOneWorkRequest } from "../../../api/work-request/work-request";
import WRRatingGuard from "../../../guards/WRRatingGuard";
import useDate from "../../../hooks/useDate";
import { trpc } from "../../../lib/trpc";
import { useAppSelector } from "../../../redux/store";
import {
  WORK_REQUEST_CANCELLED_STATUS,
  WORK_REQUEST_COMPLETED_STATUS,
  WORK_REQUEST_IN_PROGRESS_STATUS,
} from "../../../screens/WorkRequestScreen/WorkRequestScreen";
import AppButton from "../../AppButton";
import AppItemInfo from "../../AppItemInfo";
import AppMultiImage from "../../AppMultiImage";
import { AppRatings } from "../../rating/AppRatings";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import {
  DRAWER_VIEW_STATE,
  WORK_ORDER_DETAIL_DRAWER,
  WORK_ORDER_FORM_DRAWER,
} from "../AppDrawer";
import WorkRequestCancelDialogBtn from "./components/WorkRequestCancelDialogBtn";

export default function WorkRequestDetailDrawer() {
  const { formatDateToLocal, diffInDays } = useDate();
  const [searchParam, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const workRequestId = searchParam.get("workRequestId");
  const { data: workRequest, isLoading } =
    trpc.workRequest.getOneWorkRequest.useQuery(
      {
        workRequestId: workRequestId ?? "",
      },
      {
        enabled: !!workRequestId,
      }
    );

  const { data: workRequestSetting, isLoading: settingsIsLoading } =
    trpc.workRequest.getWorkRequestSettingPublic.useQuery(
      {
        projectWorkRequestCode: activeProj?.workRequestCode ?? "",
      },
      {
        enabled: !!activeProj?.workRequestCode,
        select: (res) => res.data,
      }
    );

  if (isLoading || settingsIsLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-6">
      <p className="font-sans text-2xl font-bold">{workRequest?.data?.title}</p>
      <div className="h-64">
        <AppMultiImage
          attachments={workRequest?.data?.photos ?? []}
          className="h-64 w-[500px] border-solid border-neutral-200"
        />
      </div>
      <AppItemInfo
        label="Created"
        icon={<Pencil className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workRequest?.data?.createdOn
              ? formatDateToLocal({
                  dateStr: workRequest?.data?.createdOn?.toISOString(),
                })
              : "-"}
          </p>
        }
      />
      {workRequestSetting?.descriptionVisibility === "VISIBLE" && (
        <AppItemInfo
          label="Description"
          icon={<AlignLeft className="text-primary-900" />}
          content={
            <p className="font-sans text-neutral-900">
              {workRequest?.data?.description ?? "-"}
            </p>
          }
        />
      )}
      <AppItemInfo
        label="Email"
        icon={<Mail className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workRequest?.data?.email ?? "-"}
          </p>
        }
      />
      {workRequestSetting?.contactNoVisibility === "VISIBLE" && (
        <AppItemInfo
          label="Contact No."
          icon={<Phone className="text-primary-900" />}
          content={
            <p className="font-sans text-neutral-900">
              {workRequest?.data?.contactNo ?? "-"}
            </p>
          }
        />
      )}
      <AppItemInfo
        label="Location"
        icon={<MapPin className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workRequest?.data?.dbLocation?.name ?? "-"}
          </p>
        }
      />
      {workRequestSetting?.locationDetailVisibility === "VISIBLE" && (
        <AppItemInfo
          label="Location Details"
          icon={<MapPin className="text-primary-900" />}
          content={
            <p className="font-sans text-neutral-900">
              {workRequest?.data?.location ?? "-"}
            </p>
          }
        />
      )}
      {workRequestSetting?.nameVisibility === "VISIBLE" && (
        <AppItemInfo
          label="Name"
          icon={<User className="text-primary-900" />}
          content={<p>{workRequest?.data?.name ?? "-"}</p>}
        />
      )}
      {workRequestSetting?.departmentVisibility === "VISIBLE" && (
        <AppItemInfo
          label="Department"
          icon={<Building className="text-primary-900" />}
          content={<p>{workRequest?.data?.department ?? "-"}</p>}
        />
      )}
      {workRequest?.data?.asset && (
        <>
          <AppItemInfo
            label="Equipment"
            icon={<Computer className="text-primary-900" />}
            content={<p>{workRequest?.data?.asset?.name ?? "-"}</p>}
          />
          <AppItemInfo
            label="Equipment Code"
            icon={<Computer className="text-primary-900" />}
            content={<p>{workRequest?.data?.asset?.code ?? "-"}</p>}
          />
        </>
      )}

      <AppItemInfo
        label="Tracking Code"
        icon={<Tag className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900" data-testid="tracking-code">
            {workRequest?.data?.trackingCode ?? "-"}
          </p>
        }
      />
      <WRRatingGuard>
        <AppItemInfo
          label="External Rating"
          icon={<Star className="text-primary-900" />}
          content={
            <>
              {workRequest?.data?.rating ? (
                <AppRatings
                  rating={
                    workRequest?.data?.rating ? +workRequest?.data?.rating : 0
                  }
                />
              ) : (
                <p>-</p>
              )}
            </>
          }
        />
      </WRRatingGuard>
      <AppItemInfo
        label="Linked Work Order"
        icon={<Link className="text-primary-900" />}
        content={
          workRequest?.data?.workOrder ? (
            <div
              className="flex gap-4 items-center justify-between bg-neutral-300 hover:cursor-pointer"
              onClick={() => {
                setSearchParams((p) => {
                  p.set("drawerType", WORK_ORDER_DETAIL_DRAWER);
                  p.set("drawerState", DRAWER_VIEW_STATE);
                  p.set(
                    "workOrderId",
                    workRequest?.data?.workOrder?.id?.toString() ?? ""
                  );
                  p.set("workOrderTabIdx", "0");
                  return p;
                });
              }}
            >
              <div className="flex gap-4 items-center">
                <AppMultiImage
                  attachments={workRequest?.data?.workOrder?.photos ?? []}
                />
                <div>
                  <p className="font-sans font-bold text-xl truncate">
                    {workRequest?.data?.workOrder?.subject ?? "-"}
                  </p>
                  <p className="font-sans truncate">
                    {workRequest?.data?.workOrder?.asset?.name ?? "-"}
                  </p>
                  <p className="font-sans truncate text-sm text-neutral-600">
                    {workRequest?.data?.workOrder?.location?.name ?? "-"}
                  </p>
                </div>
              </div>
              <ChevronRight className="text-primary-900 w-9 h-9 mr-3" />
            </div>
          ) : (
            <p>No work order detected</p>
          )
        }
      />
      {workRequest?.data?.workRequestStatus ===
        WORK_REQUEST_CANCELLED_STATUS && (
        <AppItemInfo
          label="Cancellation Remark"
          icon={<Ban className="text-primary-900" />}
          content={
            <p className="font-sans text-neutral-900">
              {workRequest?.data?.cancellationRemark ?? "-"}
            </p>
          }
        />
      )}
      {!(
        workRequest?.data?.workRequestStatus ===
          WORK_REQUEST_CANCELLED_STATUS ||
        workRequest?.data?.workRequestStatus ===
          WORK_REQUEST_COMPLETED_STATUS ||
        workRequest?.data?.workRequestStatus === WORK_REQUEST_IN_PROGRESS_STATUS
      ) && (
        <div className="flex gap-4">
          <WorkRequestCancelDialogBtn />
          <AppButton
            label="Create Work Order"
            onClick={() => {
              setSearchParams((p) => {
                p.set("drawerType", WORK_ORDER_FORM_DRAWER);
                p.set("drawerState", "CREATE");
                return p;
              });
            }}
            variant="outline"
          />
        </div>
      )}
    </div>
  );
}
