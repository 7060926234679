import { WorkOrderClosureRate } from "./WorkOrderClosureRate";
import { WorkOrderStatusCountSection } from "./WorkOrderStatusCountSection";
import WorkOrderTrend from "./WorkOrderTrend";
import { WorkOrderTypeApportionment } from "./WorkOrderTypeApportionment";

export function WorkOrderAnalytics() {
  return (
    <div className="grid grid-cols-4 gap-4">
      <div className="col-span-2">
        <WorkOrderTypeApportionment />
      </div>
      <div className="col-span-2">
        <WorkOrderClosureRate />
      </div>
      <div className="col-span-4">
        <WorkOrderTrend />
      </div>
      <WorkOrderStatusCountSection />
    </div>
  );
}
