import AppProjectSelect from "../../../AppProjectSelect";
import DeleteWorkOrderCommentPolicy from "../components/DeleteWorkOrderCommentPolicy";
import InventoryAdjustmentPolicy from "../components/InventoryAdjustmentPolicy";
import PrivateAttachmentAccessPolicy from "../components/PrivateAttachmentAccessPolicy";
import SettingSelectProj from "../components/SettingSelectProj";
import WorkOrderPricingPolicy from "../components/WorkOrderPricingPolicy";
import WorkOrderPullbackPolicy from "../components/WorkOrderPullbackPolicy";

export default function AccessControlTab() {
  return (
    <div className="space-y-4">
      <div className="flex items-center gap-6">
        <p className="text-sm text-slate-500">Current Project: </p>
        <SettingSelectProj />
      </div>
      <InventoryAdjustmentPolicy />
      <WorkOrderPricingPolicy />
      <WorkOrderPullbackPolicy />
      <PrivateAttachmentAccessPolicy />
      <DeleteWorkOrderCommentPolicy />
    </div>
  );
}
