import { WorkOrderStatusCount } from "@cerev-cmms/zod-types";

interface WorkOrderStatusCountCardProps {
  statusCount: WorkOrderStatusCount;
}

export function WorkOrderStatusCountCard({
  statusCount,
}: WorkOrderStatusCountCardProps) {
  return (
    <div
      className="rounded-lg p-4 border"
      style={{
        borderLeft: `4px solid ${statusCount.colorHex || "#CBD5E0"}`,
      }}
    >
      <div className="flex flex-col">
        <span className="text-3xl font-bold text-gray-800">
          {statusCount.count}
        </span>
        <span className="mt-1 text-sm text-gray-600">
          {statusCount.name} work order{statusCount.count !== 1 ? "s" : ""}
        </span>
      </div>
    </div>
  );
}
