import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import AppButton from "@/components/AppButton";
import AppTextField from "@/components/AppTextField";
import { DRAWER_CREATE_STATE } from "./AppDrawer";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CreateWorkTradeInput,
  UpdateWorkTradeInput,
  createWorkTradeSchema,
  updateWorkTradeSchema,
} from "@cerev-cmms/zod-types";
import { trpc } from "../../lib/trpc";
import { useAppSelector } from "../../redux/store";

export default function WorkOrderTradeFormDrawer() {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [searchParams, setSearchParams] = useSearchParams();
  const drawerState = searchParams.get("drawerState");
  const id = searchParams.get("workOrderTradeId");
  const util = trpc.useUtils();

  const { data: workOrderTrade } = trpc.workOrders.getOneWorkTrade.useQuery(
    { id: Number(id) },
    { enabled: !!id }
  );

  useEffect(() => {
    if (workOrderTrade) {
      methods.reset(workOrderTrade);
    }
  }, [workOrderTrade]);

  const { mutate: createMutation, isPending: isCreateLoading } =
    trpc.workOrders.createWorkTrade.useMutation({
      onSuccess: () => {
        util.workOrders.getWorkTrades.invalidate();
        util.workOrders.getOneWorkTrade.invalidate();
        setSearchParams((p) => {
          p.delete("drawerType");
          p.delete("drawerState");
          p.delete("workOrderTradeId");
          return p;
        });
      },
    });
  const { mutate: updateMutation, isPending: isUpdateLoading } =
    trpc.workOrders.updateWorkTrade.useMutation({
      onSuccess: () => {
        util.workOrders.getWorkTrades.invalidate();
        util.workOrders.getOneWorkTrade.invalidate();
        setSearchParams((p) => {
          p.delete("drawerType");
          p.delete("drawerState");
          p.delete("workOrderTradeId");
          return p;
        });
      },
    });

  const methods = useForm<CreateWorkTradeInput | UpdateWorkTradeInput>({
    resolver: zodResolver(
      drawerState === DRAWER_CREATE_STATE
        ? createWorkTradeSchema
        : updateWorkTradeSchema
    ),
    defaultValues: {
      name: workOrderTrade?.name || "",
      order: workOrderTrade?.order || 0,
      projectId: Number(activeProj?.id) ?? -1,
    },
  });

  const onSubmit = (data: CreateWorkTradeInput | UpdateWorkTradeInput) => {
    if (drawerState === DRAWER_CREATE_STATE) {
      createMutation(data as CreateWorkTradeInput);
    } else {
      updateMutation({
        ...data,
        id: Number(id),
      } as UpdateWorkTradeInput);
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="space-y-4">
        <h2 className="text-2xl font-bold">
          {drawerState === DRAWER_CREATE_STATE ? "Create" : "Update"} Work Order
          Trade
        </h2>
        <AppTextField
          name="name"
          label="Trade Name"
          placeholder="Enter trade name"
        />
        <AppButton
          label={drawerState === DRAWER_CREATE_STATE ? "Create" : "Update"}
          onClick={methods.handleSubmit(onSubmit)}
          isLoading={isCreateLoading || isUpdateLoading}
        />
      </div>
    </FormProvider>
  );
}

