import * as React from "react";
import { Text, View } from "@react-pdf/renderer";
import { useJkrPataWorkOrder } from "..";

export function WorkCompletionSection() {
  const workOrder = useJkrPataWorkOrder();

  return (
    <View style={{ border: "1px solid black" }} wrap={false}>
      <View style={{ backgroundColor: "#f0f0f0", padding: 4 }}>
        <Text style={{ fontSize: 9, fontWeight: "bold" }}>
          F. Perakuan Siap Kerja
        </Text>
      </View>
      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: 1, padding: 8, borderRight: "1px solid black" }}>
          <Text style={{ fontSize: 9 }}>
            Pengesahan oleh Pegawai Penyelia Kontraktor (Jika Berkenaan)
          </Text>
          <View style={{ marginTop: 20 }}>
            <Text style={{ fontSize: 9 }}>Tandatangan:</Text>
            <Text style={{ fontSize: 9, marginTop: 4, fontWeight: "bold" }}>
              Cap Nama & Jawatan:
            </Text>
          </View>
        </View>
        <View style={{ flex: 1, padding: 8 }}>
          <Text style={{ fontSize: 9 }}>
            Pengesahan oleh Unit Fasiliti & Penyelenggaraan
          </Text>
          <View style={{ marginTop: 20 }}>
            <Text style={{ fontSize: 9, marginTop: 4, fontWeight: "bold" }}>
              Cap Nama & Jawatan: {workOrder?.createdBy?.name ?? "-"} -{" "}
              {workOrder?.createdBy?.position ?? "-"}
            </Text>
            <Text style={{ fontSize: 9, marginTop: 4, fontWeight: "bold" }}>
              Tarikh: {workOrder?.completedOn ?? "-"}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}
