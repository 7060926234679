import AppButton from "@/components/AppButton";
import AppTextAreaField from "@/components/AppTextAreaField";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form } from "@/components/ui/form";
import { trpc } from "@/lib/trpc";
import { updateCorrectiveRootCauseSchema } from "@cerev-cmms/zod-types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { AlignLeft, Pencil } from "lucide-react";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import AppItemInfo from "../../../../../AppItemInfo";
import { useInvestigationEnabled } from "../../../../../../hooks/useFeatures";

type FormType = {
  workOrderId: number;
  correctiveAction: string;
  rootCause: string;
};

export default function InvestigationSection() {
  const [searchParam] = useSearchParams();
  const workOrderId = searchParam.get("workOrderId");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const utils = trpc.useUtils();
  const qc = useQueryClient();

  const form = useForm<FormType>({
    resolver: zodResolver(updateCorrectiveRootCauseSchema),
  });

  const { data: workOrder } = trpc.workOrders.getOneWorkOrder.useQuery(
    { workOrderId: Number(workOrderId) ?? 0 },
    {
      enabled: !!workOrderId,
    }
  );

  // Reset form when workOrder data changes
  useEffect(() => {
    if (!form.formState.isDirty && workOrder) {
      form.reset({
        workOrderId: workOrder.id,
        correctiveAction: workOrder.correctiveAction ?? "",
        rootCause: workOrder.rootCause ?? "",
      });
    }
  }, [workOrder]);

  const { mutate: updateInvestigation, isPending } = 
    trpc.workOrders.updateCorrectiveRootCause.useMutation({
      onSuccess: () => {
        setIsDialogOpen(false);
        form.reset();
        utils.workOrders.getOneWorkOrder.invalidate();
        qc.invalidateQueries({
          predicate: (q) =>
            (q.queryKey[0] as string).includes("defect") ||
            (q.queryKey[0] as string).includes("workOrder"),
        });
      },
    });

  const onSubmit: SubmitHandler<FormType> = (data) => {
    updateInvestigation({
      workOrderId: Number(workOrderId),
      correctiveAction: data.correctiveAction,
      rootCause: data.rootCause,
    });
  };


  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <p className="text-xl font-bold">Investigation</p>
        <AppButton
          variant="outline"
          onClick={() => setIsDialogOpen(true)}
          icon={<Pencil className="h-4 w-4" />}
          label="Update Investigation"
        />
      </div>

      {/* Display current values */}
      <div className="space-y-6">
        <AppItemInfo
          label="Root Cause"
          icon={<AlignLeft />}
          content={<p>{workOrder?.rootCause ?? "-"}</p>}
        />
        <AppItemInfo
          label="Corrective Action"
          icon={<AlignLeft />}
          content={<p>{workOrder?.correctiveAction ?? "-"}</p>}
        />
      </div>

      {/* Update Dialog */}
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="max-w-2xl overflow-y-scroll max-h-screen">
          <DialogHeader>
            <DialogTitle>Update Investigation</DialogTitle>
          </DialogHeader>
          <Form {...form}>
            <div className="space-y-4">
              <AppTextAreaField
                label="Root Cause"
                name="rootCause"
                placeholder="Enter root cause..."
              />
              <AppTextAreaField
                label="Corrective Action"
                name="correctiveAction"
                placeholder="Enter corrective action..."
              />
              <div className="flex justify-end gap-2">
                <AppButton
                  type="button"
                  variant="outline"
                  label="Cancel"
                  onClick={() => setIsDialogOpen(false)}
                />
                <AppButton
                  onClick={form.handleSubmit(onSubmit)}
                  label="Save"
                  isLoading={isPending}
                />
              </div>
            </div>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
