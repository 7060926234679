import * as React from "react";
import { Page, Text, View } from "@react-pdf/renderer";
import { ReferenceBox } from "./ReferenceBox";
import { ComplaintInfoSection } from "./ComplaintInfoSection";
import { InvestigationSection } from "./InvestigationSection";
import { SparePartsSection } from "./SparePartsSection";
import { RepairActionSection } from "./RepairActionSection";
import { ContractorSection } from "./ContractorSection";
import { WorkCompletionSection } from "./WorkCompletionSection";
import { FooterPageNumber } from "./FooterPageNumber";

export function PataWorkOrderPage() {
  return (
    <Page
      size="A4"
      style={{
        paddingHorizontal: 35,
        paddingVertical: 20,
        flexDirection: "column",
        gap: 8,
      }}
    >
      {/* Header */}
      <View style={{ alignItems: "flex-end", marginBottom: 12 }} fixed>
        <Text style={{ fontSize: 10 }}>JKR.PATA.F7 / 4</Text>
      </View>

      {/* Title */}
      <View style={{ alignItems: "center", marginBottom: 12 }} fixed>
        <Text
          style={{
            fontSize: 12,
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          BORANG ARAHAN SIASATAN & PENYELENGGARAAN PEMBAIKAN
        </Text>
      </View>

      <ReferenceBox />
      <ComplaintInfoSection />
      <InvestigationSection />
      <SparePartsSection />
      <RepairActionSection />
      <ContractorSection />
      <WorkCompletionSection />
      <FooterPageNumber />
    </Page>
  );
}
