import { WorkOrderPriority } from "@cerev-cmms/prisma";
import { ColumnDef } from "@tanstack/react-table";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  DRAWER_UPDATE_STATE,
  WORK_ORDER_PRIORITY_FORM_DRAWER,
} from "../../../../components/drawers/AppDrawer";
import { trpc } from "../../../../lib/trpc";
import AppButton from "../../../../components/AppButton";
import {
  DialogHeader,
  DialogFooter,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from "../../../../components/ui/dialog";

export const workPriorityColumns: ColumnDef<WorkOrderPriority>[] = [
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const [searchParams, setSearchParams] = useSearchParams();
      const deleteMutation =
        trpc.workOrders.deleteWorkOrderPriority.useMutation();
      const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
      const [priorityToDelete, setPriorityToDelete] = useState<number | null>(
        null
      );
      const utils = trpc.useUtils();

      const handleEdit = (id: number) => {
        setSearchParams({
          drawerType: WORK_ORDER_PRIORITY_FORM_DRAWER,
          drawerState: DRAWER_UPDATE_STATE,
          workOrderPriorityId: id.toString(),
        });
      };

      const handleDeleteClick = (id: number) => {
        setPriorityToDelete(id);
        setDeleteDialogOpen(true);
      };

      const handleDeleteConfirm = async () => {
        if (priorityToDelete) {
          await deleteMutation.mutateAsync({ id: priorityToDelete });
          utils.workOrders.getWorkOrderPriorities.invalidate();
          setDeleteDialogOpen(false);
          setPriorityToDelete(null);
        }
      };

      return (
        <div className="flex space-x-2">
          <AppButton
            variant="outline"
            size="sm"
            label="Edit"
            onClick={() => {
              handleEdit(row.original.id);
            }}
          />
          <AppButton
            variant="destructive"
            size="sm"
            label="Delete"
            onClick={() => handleDeleteClick(row.original.id)}
          />
          <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogDescription>
                  Are you sure you want to delete this work order priority? This
                  action cannot be undone.
                </DialogDescription>
              </DialogHeader>
              <DialogFooter>
                <AppButton
                  label="Cancel"
                  onClick={() => setDeleteDialogOpen(false)}
                  variant="outline"
                />
                <AppButton
                  label="Delete"
                  onClick={handleDeleteConfirm}
                  variant="destructive"
                />
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      );
    },
  },
];
