import * as React from 'react';
import { Text } from '@react-pdf/renderer';

export function FooterPageNumber() {
  return (
    <Text
      style={{
        position: 'absolute',
        fontSize: 8,
        bottom: 16,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
      }}
      render={({ pageNumber, totalPages }) =>
        `Page ${pageNumber} / ${totalPages}`
      }
      fixed
    />
  );
}
