import {
  Computer,
  Download,
  Files,
  Link,
  MapPin,
  MoreHorizontal,
} from "lucide-react";
import { Button } from "../../../components/ui/button";
import {
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "../../../components/ui/dropdown-menu";
import {
  useWorkOrderIsMultiActionMode,
  useWorkOrderMultiSelectActions,
  WorkOrderMultiActionType,
} from "../../../stores/workOrderMultiActionStore/workOrderMultiActionStore";
import { useCustomCsvControllerGetCustomCsvSettings } from "../../../api/custom-csv/custom-csv";
import { useAppSelector } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import {
  useWorkOrderMultiExportPdfActions,
  useWorkOrderMultiExportPdfSelectionMode,
} from "../../../stores/workOrderMultiExportPdfStore/workOrderMultiExportPdfStore";
import { CustomCsvType } from "../../../api/model";

export default function MoreActions() {
  const { setMultiActionMode } = useWorkOrderMultiSelectActions();
  const selectionMode = useWorkOrderMultiExportPdfSelectionMode();
  const { setSelectionMode } = useWorkOrderMultiExportPdfActions();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data } = useCustomCsvControllerGetCustomCsvSettings(
    activeProj?.id?.toString() ?? "",
    {
      customCsvType: CustomCsvType.WORK_ORDER,
    },
    {
      query: {
        enabled: !!activeProj,
        select: (res) => res.data.data,
      },
    }
  );

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="text-slate-400">
          <MoreHorizontal />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>More Actions</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="flex gap-4"
          onClick={() => {
            setMultiActionMode(WorkOrderMultiActionType.LINK_EQUIPMENT);
          }}
        >
          <Computer className="w-4 h-4" />
          <p>Link Equipment</p>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="flex gap-4"
          onClick={() => {
            setMultiActionMode(WorkOrderMultiActionType.LINK_LOCATION);
          }}
        >
          <MapPin className="w-4 h-4" />
          <p>Link Location</p>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="flex gap-4"
          onClick={() => {
            setMultiActionMode(WorkOrderMultiActionType.MULTI_EXPORT_PDF);
          }}
        >
          <Files className="w-4 h-4" />
          <p>Mutliple Export</p>
        </DropdownMenuItem>
        {data && data?.length > 0 && <DropdownMenuSeparator />}
        {data?.map((setting) => (
          <DropdownMenuItem
            key={setting.id}
            onClick={() => {
              window.open(`/custom-csv/${setting.id}`);
            }}
            className="flex gap-4"
          >
            <Download className="w-4 h-4" />
            <p>{setting.name}</p>
          </DropdownMenuItem>
        ))}
        {activeProj?.code === "IPM" && (
          <DropdownMenuItem
            className="flex gap-4"
            onClick={() => {
              window.open("/custom-csv/parts-and-accessories-costing");
            }}
          >
            <Download className="w-4 h-4" />
            <p>Summary</p>
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
