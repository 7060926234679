import { useQueryClient } from "@tanstack/react-query";
import { WorkRequestSetting } from "../../../../../api/model";
import { useAppSelector } from "../../../../../redux/store";
import {
  useWorkRequestSettingControllerGetOneWorkRequestSetting,
  useWorkRequestSettingControllerUpdateWorkRequestSetting,
} from "../../../../../api/work-request-setting/work-request-setting";
import { TableCell } from "../../../../../components/ui/table";
import { Checkbox } from "../../../../../components/ui/checkbox";
import { Loader2 } from "lucide-react";
import { cn } from "../../../../../lib/utils";
import { trpc } from "../../../../../lib/trpc";

interface WorkRequestSettingVisibilityCheckboxProps {
  keyValue:
    | "locationDetailVisibility"
    | "contactNoVisibility"
    | "subjectVisibility"
    | "descriptionVisibility"
    | "photoVisibility"
    | "assetVisibility"
    | "locationVisibility"
    | "emailVisibility"
    | "nameVisibility"
    | "departmentVisibility";
}

export default function WorkRequestSettingVisibilityCheckbox({
  keyValue,
}: WorkRequestSettingVisibilityCheckboxProps) {
  const qc = useQueryClient();
  const utils = trpc.useUtils();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data: workReqSettings } =
    trpc.workRequest.getOneWorkRequestSetting.useQuery(
      {
        projectId: activeProj?.id ?? 0,
      },
      {
        enabled: !!activeProj,
        select: (res) => res.data,
      }
    );

  const { mutate, isPending } =
    trpc.workRequest.updateWorkRequestSetting.useMutation({
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (q) => {
            utils.workRequest.getOneWorkRequestSetting.invalidate();
            return (q.queryKey[0] as string).includes("work-request-setting");
          },
        });
      },
    });

  return (
    <div className="flex items-center gap-2">
      <Checkbox
        // Do not allow user to change subject, it has to be required at all times otherwise it would break the app
        disabled={
          keyValue === "subjectVisibility" || keyValue === "emailVisibility"
        }
        checked={workReqSettings?.[keyValue] === "VISIBLE"}
        onCheckedChange={(e) => {
          if (!workReqSettings) return;
          mutate({
            ...workReqSettings,
            projectId: activeProj?.id ?? 0,
            [keyValue]: e ? "VISIBLE" : "HIDDEN",
          });
        }}
      />
      {isPending && (
        <Loader2 className={cn("h-6 w-6 animate-spin text-primary-900")} />
      )}
    </div>
  );
}
