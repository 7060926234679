import { z } from "zod";

export const getLegalCompliancesInput = z.object({
  projectId: z.number(),
});

export type GetLegalCompliancesInput = z.infer<typeof getLegalCompliancesInput>;

export const getExpiredContractSchema = z.object({
  projectId: z.number(),
});

export type GetExpiredContractInput = z.infer<typeof getExpiredContractSchema>;

export type ExpiredContractStats = {
  totalContracts: number;
  expiredContracts: number;
  expiryPercentage: number;
};
