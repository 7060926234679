import { trpc } from "@/lib/trpc";
import { useAppSelector } from "@/redux/store";
import { ContractStatusCard } from "./ContractStatusCard";

export function ContractMetrics() {
  const activeProject = useAppSelector((state) => state.root.activeProject);

  const { data: contractStats } = trpc.analytics.getExpiredContract.useQuery(
    {
      projectId: Number(activeProject?.id),
    },
    {
      enabled: !!activeProject?.id,
    }
  );

  if (!contractStats) return null;

  return (
    <div className="grid gap-4 grid-cols-2">
      <ContractStatusCard
        title="Active Contracts"
        value={contractStats.totalContracts - contractStats.expiredContracts}
        total={contractStats.totalContracts}
        className="bg-green-50"
      />
      <ContractStatusCard
        title="Expired Contracts"
        value={contractStats.expiredContracts}
        total={contractStats.totalContracts}
        className="bg-red-50"
      />
    </div>
  );
} 