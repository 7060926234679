import { format, isValid, parse } from "date-fns";
import { useEffect, useState } from "react";
import { FormItem, FormLabel } from "./ui/form";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { CalendarIcon } from "lucide-react";
import { cn } from "../utils/util";
import { Calendar } from "./ui/calendar";

interface DatePickerProps {
  label: string;
  value: string;
  onChange: (value: Date | null) => void;
  errorMessage?: string;
}

const DatePicker = ({
  label,
  value,
  onChange,
  errorMessage,
}: DatePickerProps) => {
  const [date, setDate] = useState<Date | null>(null);
  const [inputError, setInputError] = useState<string>("");

  const handleDateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    // If input is empty, clear the date
    if (!inputValue || inputValue === "") {
      setDate(null);
      setInputError("");
      onChange(null);
      return;
    }

    const parsedDate = new Date(inputValue);

    if (!isValid(parsedDate)) {
      setInputError("Invalid Date");
      setDate(null);
      onChange(null);
    } else {
      setInputError("");
      setDate(parsedDate);
      onChange(parsedDate);
    }
  };

  useEffect(() => {
    if (value) {
      const parsedDate = parse(value, "dd/MM/yyyy", new Date());
      if (isValid(parsedDate)) {
        setDate(parsedDate);
      }
    }
  }, [value]);

  const formatDateForInput = (date: Date | null) => {
    if (!date || !isValid(date)) return "";
    return format(date, "yyyy-MM-dd"); // Format for HTML date input
  };

  return (
    <FormItem className="flex flex-col">
      <FormLabel>{label}</FormLabel>
      <div className="relative w-full">
        <Input
          type="date"
          value={formatDateForInput(date)}
          onChange={handleDateInput}
          className={cn("focus-visible:ring-primary-900 bg-slate-50", {
            "ring-2 ring-offset-1 ring-red-500": !!errorMessage || !!inputError,
          })}
        />
        {(inputError || errorMessage) && (
          <div className="mt-1 left-0 text-red-500 text-xs">
            {inputError || errorMessage}
          </div>
        )}
      </div>
    </FormItem>
  );
};

export default DatePicker;
