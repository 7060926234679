import * as React from "react";
import { Text, View } from "@react-pdf/renderer";
import { useJkrPataWorkOrder } from "..";

export function HeaderForm() {
  const workOrder = useJkrPataWorkOrder();

  return (
    <View style={{ gap: 8 }}>
      <View style={{ flexDirection: "row", gap: 4 }}>
        <Text style={{ width: 120, fontSize: 11 }}>No. Aduan</Text>
        <Text style={{ fontSize: 11 }}>: {`${workOrder.refNo ?? "______________________________________________________________"}`}</Text>
      </View>

      <View style={{ flexDirection: "row", gap: 4 }}>
        <Text style={{ width: 120, fontSize: 11 }}>Nama Pelanggan</Text>
        <Text style={{ fontSize: 11 }}>: {`${workOrder.workRequests?.[0]?.name ?? "_______________________________________________________"}`}</Text>
      </View>

      <View style={{ flexDirection: "row", gap: 4 }}>
        <Text style={{ width: 120, fontSize: 11 }}>No. Telefon</Text>
        <Text style={{ fontSize: 11 }}>: {`${workOrder.workRequests?.[0]?.contactNo ?? "_______________________________________________________"}`}</Text>
      </View>

      <View style={{ flexDirection: "row", gap: 4 }}>
        <Text style={{ width: 120, fontSize: 11 }}>Jabatan</Text>
        <Text style={{ fontSize: 11 }}>: {`${workOrder.workRequests?.[0]?.dbLocation?.name ?? "_______________________________________________________"}`}</Text>
      </View>

      <View style={{ flexDirection: "row", gap: 4 }}>
        <Text style={{ width: 120, fontSize: 11 }}>Premis</Text>
        <Text style={{ fontSize: 11 }}>: KOMPLEKS KEMENTERIAN DALAM NEGERI(KDN) PERLIS</Text>
      </View>

      <View style={{ flexDirection: "row", gap: 4 }}>
        <Text style={{ width: 120, fontSize: 11 }}>No. DPA</Text>
        <Text style={{ fontSize: 11 }}>: 1146001MYS.090019.BD0001</Text>
      </View>

      <View style={{ flexDirection: "row", gap: 4 }}>
        <Text style={{ width: 120, fontSize: 11 }}>Tarikh / Masa</Text>
        <Text style={{ fontSize: 11 }}>: {`${workOrder.workRequests?.[0]?.createdOn ?? "_______________________________________________________"}`}</Text>
      </View>

      <View style={{ flexDirection: "row", gap: 4 }}>
        <Text style={{ width: 120, fontSize: 11 }}>Skop Perkhidmatan</Text>
        <Text style={{ fontSize: 11 }}>: Mode Aduan</Text>
      </View>
    </View>
  );
}

