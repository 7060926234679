import { Link } from "lucide-react";
import AppButton from "../../../components/AppButton";
import { useAppSelector } from "../../../redux/store";

export default function LinkToPublicWR() {
  const baseUrl = window.origin;
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const onNavigate = () => {
    if (!activeProj) return;
    window.open(
      `${baseUrl}/public/work-request/${activeProj?.workRequestCode}`,
      "_blank"
    );
  };

  return (
    <AppButton
      variant="outline"
      label="Go to link"
      icon={<Link />}
      onClick={onNavigate}
    />
  );
}
