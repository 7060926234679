import * as React from "react";
import { Text, View } from "@react-pdf/renderer";

export function SparePartsSection() {
  return (
    <View style={{ border: "1px solid black" }} wrap={false} >
      <View style={{ backgroundColor: "#f0f0f0", padding: 4 }}>
        <Text style={{ fontSize: 9, fontWeight: "bold" }}>C. Butiran Alat Ganti (Jika Berkenaan)</Text>
      </View>
      <View>
        <View style={{ flexDirection: "row", borderBottom: "1px solid black" }}>
          <View style={{ flex: 2, borderRight: "1px solid black", padding: 4 }}>
            <Text style={{ fontSize: 9 }}>Jenis Alat Ganti</Text>
          </View>
          <View style={{ flex: 2, borderRight: "1px solid black", padding: 4 }}>
            <Text style={{ fontSize: 9 }}>Keterangan</Text>
          </View>
          <View style={{ flex: 2, borderRight: "1px solid black", padding: 4 }}>
            <Text style={{ fontSize: 9 }}>Harga Alat Ganti/Stok</Text>
          </View>
          <View style={{ flex: 1, borderRight: "1px solid black", padding: 4 }}>
            <Text style={{ fontSize: 9 }}>Kuantiti</Text>
          </View>
          <View style={{ flex: 1, padding: 4 }}>
            <Text style={{ fontSize: 9 }}>Jumlah</Text>
          </View>
        </View>
        <View style={{ height: 30 }}>
          {/* <Text style={{ fontSize: 9, fontWeight: "bold" }}>Value</Text> */}
        </View>
      </View>
    </View>
  );
}
