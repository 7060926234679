import { useState } from "react";
import { Control } from "react-hook-form";

interface EditableCellProps {
  index: number;
  fieldName: string;
  control: Control<any>;
  className?: string;
}

export function EditableCell({ index, fieldName, control, className = "" }: EditableCellProps) {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <td 
      className={`border p-2 text-sm text-right transition-colors ${
        isEditing ? "border-blue-400 border-2" : "border-gray-300"
      } ${className}`}
    >
      <input
        type="text"
        {...control.register(`kpiRows.${index}.${fieldName}`)}
        className="w-full bg-transparent text-right focus:outline-none"
        onFocus={() => setIsEditing(true)}
        onBlur={() => setIsEditing(false)}
      />
    </td>
  );
} 