import * as React from "react";
import { Document, Font, Image, Page, Text, View } from "@react-pdf/renderer";

Font.register({
  family: "Fira Sans",
  fonts: [
    {
      src: "https://storage.googleapis.com/cerev-dev.appspot.com/public-fonts/FiraSans-Regular.ttf",
      fontStyle: "normal",
      fontWeight: "normal",
    },
    {
      src: "https://storage.googleapis.com/cerev-dev.appspot.com/public-fonts/FiraSans-Bold.ttf",
      fontStyle: "normal",
      fontWeight: "bold",
    },
  ],
});

interface JRKWorkRequestQrProps {
  qrCodeUrl?: string;
}

export function JRKWorkRequestQr({
  qrCodeUrl = "https://storage.googleapis.com/cerev-prod.appspot.com/public/Zefhill/Screenshot%202024-10-17%20at%209.49.29%E2%80%AFAM.png",
}: JRKWorkRequestQrProps) {
  return (
    <Document style={{ fontFamily: "Fira Sans" }}>
      <Page
        size="A4"
        style={{
          paddingHorizontal: 40,
          paddingVertical: 24,
          flexDirection: "column",
          gap: 32,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 24,
            height: 100,
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Image
            src="https://storage.googleapis.com/cerev-prod.appspot.com/public/Zefhill/logo%20jkr.png"
            style={{ height: 115, width: 150 }}
          />
          <Image
            src="https://storage.googleapis.com/cerev-prod.appspot.com/public/Zefhill/zefhill_logo.png"
            style={{ height: 115, width: 150 }}
          />
        </View>
        <Image src={qrCodeUrl} style={{ height: 300, width: 300 }} />
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 12,
          }}
        >
          <Text style={{ fontSize: 24, fontWeight: "bold" }}>
            Scan QR Code to Submit Work Request
          </Text>
          <Text style={{ fontSize: 16 }}>Kementerian Dalam Negeri Perlis</Text>
        </View>
      </Page>
    </Document>
  );
}
