import { useRef } from "react";
import AppButton from "./AppButton";
import { Delete, Files } from "lucide-react";
import { RouterOutputs } from "@cerev-cmms/trpc";

// Use the tRPC Attachment type
type TRPCAttachment = RouterOutputs["assets"]["getOneAsset"]["additionalDocuments"][number];

interface AppDocumentUploadTRPCProps {
  uploadedDocs?: TRPCAttachment[];
  onDeleteUploadedDoc?: (att: TRPCAttachment) => Promise<void>;
  files: File[];
  label: string;
  onChange: (files: File[]) => void;
  onDelete?: (file: File) => void;
  error?: boolean;
  helperText?: string;
}

export default function AppDocumentUploadTRPC({
  files,
  uploadedDocs,
  label,
  onChange,
  onDelete,
  error,
  helperText,
  onDeleteUploadedDoc,
}: AppDocumentUploadTRPCProps) {
  const fileRef = useRef(null);

  const onFileAttClicked = () => {
    (fileRef.current as any).click();
  };

  const onObtainedFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fileArray = [];
    const fileList = event.target.files;
    if (!fileList) return;
    for (let i = 0; i < fileList.length; i++) {
      fileArray.push(fileList[i]);
    }
    onChange([...files, ...fileArray]);
  };

  const onDeleteFile = (file: File) => {
    if (onDelete) onDelete(file);
  };

  return (
    <div className="flex flex-col gap-4">
      <p>{label}</p>
      {uploadedDocs && uploadedDocs.length > 0 && (
        <div className="flex flex-col gap-6">
          {uploadedDocs.map((att, idx) => (
            <div
              className="flex items-center p-4 bg-neutral-300 rounded-md justify-between"
              key={att.id}
            >
              <div className="font-sans">{att.name}</div>
              <AppButton
                variant="destructive"
                icon={<Delete />}
                onClick={() => {
                  if (onDeleteUploadedDoc) onDeleteUploadedDoc(att);
                }}
              />
            </div>
          ))}
        </div>
      )}
      {files && files.length > 0 && (
        <div className="flex flex-col gap-2">
          {files.map((fl, idx) => {
            return (
              <div
                className="flex items-center p-4 bg-neutral-300 rounded-md justify-between"
                key={idx}
              >
                <div className="font-sans">{fl.name}</div>
                <AppButton
                  variant="destructive"
                  icon={<Delete />}
                  onClick={() => {
                    onDeleteFile(fl);
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
      <AppButton
        className="flex-grow"
        label="Add Document"
        variant="outline"
        icon={<Files />}
        onClick={onFileAttClicked}
      />
      <input
        ref={fileRef}
        type="file"
        capture="user"
        onChange={onObtainedFiles}
        className="hidden"
        multiple
        data-testid={`document-${label}`}
      />
      {error && (
        <div className="text-red-700 font-sans text-xs">{helperText}</div>
      )}
    </div>
  );
} 