import { Loader2 } from "lucide-react";
import { CerevLogo } from "../icons/CerevLogo";

export function AppCenterLoading() {
  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center">
      <div className="h-20 w-20 mb-2">
        <CerevLogo />
      </div>
      <p className="font-bold text-2xl mb-6">Cerev</p>
      <div className="flex flex-col items-center justify-center gap-4">
        <Loader2 className="text-primary-900 animate-spin w-12 h-12" />
        <p className="text-slate-900 font-bold">Compiling your PDF...</p>
        <p className="text-slate-500 text-sm">
          This process may take up to 5 - 10 mins depending on size of data...
        </p>
      </div>
    </div>
  );
}
