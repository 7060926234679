import React, { useRef } from "react";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { format } from "date-fns";
import { trpc } from "../../../lib/trpc";
import { KpiCard } from "./KpiCard";
import { useReactToPrint } from "react-to-print";
import AppButton from "../../../components/AppButton";
import { Printer } from "lucide-react";

export default function JkrKpiReport() {
  const { data, isLoading, error } = trpc.analytics.getJkrKpiReport.useQuery({
    projectId: 1, // Replace with actual project ID
    electricalKeyword: "electrical", // Replace with actual keyword
  });

  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({
    contentRef,
    pageStyle: "@page { size: auto; margin: 0.5in; }",
    copyShadowRoots: true,
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const kpis = [
    {
      code: "KPI 1A",
      description: "Customer Satisfaction Survey Rating > 80%",
      value: data?.kpi1a ?? 0,
      threshold: 80,
    },
    {
      code: "KPI 2C",
      description: "Electrical PM Checklist Completion",
      value: data?.kpi2c ?? 0,
      threshold: 80,
    },
    {
      code: "KPI 1B",
      description: "Defect Rating",
      value: data?.kpi1b ?? 0,
      threshold: 80,
    },
    {
      code: "KPI 2D",
      description: "Engineering Reports & Recommendation Action 100% taken",
      value: 0,
      threshold: 80,
    },
    {
      code: "KPI 1C",
      description: "Defect Creation Time < 15 mins",
      value: data?.kpi1c ?? 0,
      threshold: 80,
    },
    {
      code: "KPI 2E",
      description:
        "Work-done as specification and asset quality meet standards",
      value: 0,
      threshold: 80,
    },
    {
      code: "KPI 1D",
      description: "Execution Time > 95%",
      value: 0,
      threshold: 80,
    },
    {
      code: "KPI 3A",
      description: "Energy Conservation Programs",
      value: 0,
      threshold: 80,
    },
    {
      code: "KPI 1E",
      description: "Pending / Backlog Work Order Completion 100%",
      value: 0,
      threshold: 80,
    },
    {
      code: "KPI 3B",
      description: "Building Energy Index (BEI)",
      value: 0,
      threshold: 80,
    },
    {
      code: "KPI 1F",
      description: "Self Finding Work Order > 80% from Total Work Order",
      value: 0,
      threshold: 80,
    },
    {
      code: "KPI 3C",
      description: "Utility Consumption 100% No Wastage",
      value: 0,
      threshold: 80,
    },
    {
      code: "KPI 1G",
      description: "Critical Services",
      value: 0,
      threshold: 80,
    },
    {
      code: "KPI 4A",
      description: "Relevant Acts & Regulations 100% Comply",
      value: 0,
      threshold: 80,
    },
    { code: "KPI 1H", description: "Normal Services", value: 0, threshold: 80 },
    {
      code: "KPI 4B",
      description: "HSE Programs 100% implemented",
      value: 0,
      threshold: 80,
    },
    {
      code: "KPI 2A",
      description: "PPM For Architecture and C&S assets",
      value: 0,
      threshold: 80,
    },
    {
      code: "KPI 4C",
      description: "Reports submitted 100% on time with sufficient content",
      value: 0,
      threshold: 80,
    },
    {
      code: "KPI 2B",
      description: "PPM for Mechanical assets",
      value: 0,
      threshold: 80,
    },
  ];

  const achievedKpis = kpis.filter((kpi) => kpi.value >= kpi.threshold).length;
  const failedKpis = kpis.length - achievedKpis;

  return (
    <div>
      <div className="space-y-4" ref={contentRef}>
        <div className="flex">
          <h2 className="text-2xl font-bold w-full text-center">
            {format(new Date(), "MMMM yyyy")}
          </h2>
          <AppButton
            className="print:hidden"
            variant="outline"
            icon={<Printer className="w-4 h-4" />}
            onClick={() => reactToPrintFn()}
          >
            Print
          </AppButton>
        </div>

        <div className="flex space-x-4">
          <Card className="w-full flex gap-20 items-center justify-center py-4 shadow-none">
            <div className="flex flex-col gap-4 items-center">
              <p className="font-medium">Failed KPIs</p>
              <p className="text-2xl font-bold">{failedKpis}</p>
            </div>
            <div className="flex flex-col gap-4 items-center">
              <p className="font-medium">Achieved KPIs</p>
              <p className="text-2xl font-bold">{achievedKpis}</p>
            </div>
          </Card>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {kpis.map((kpi) => (
            <KpiCard key={kpi.code} {...kpi} kpiCode={kpi.code} />
          ))}
        </div>
      </div>
    </div>
  );
}
