import { CerevLogo } from "../icons/CerevLogo";

export function AppCenterError() {
  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center">
      <div className="h-20 w-20 mb-2">
        <CerevLogo />
      </div>
      <p className="font-bold text-2xl mb-6">Cerev</p>
      <div className="text-red-500">Sorry, Something went wrong.. </div>
    </div>
  );
}
