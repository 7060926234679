import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { trpc } from "@/lib/trpc";
import { useAppSelector } from "@/redux/store";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";

export default function WorkTradeSelect() {
  const [searchParams] = useSearchParams();
  const workOrderId = searchParams.get("workOrderId");
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [currentWorkTradeId, setCurrentWorkTradeId] = useState<string>("");
  const utils = trpc.useUtils();

  const { data: workOrder, isLoading: isWorkOrderLoading } = trpc.workOrders.getOneWorkOrder.useQuery(
    { workOrderId: Number(workOrderId) },
    { enabled: !!workOrderId }
  );

  const { data: workTrades, isLoading: isWorkTradesLoading } = trpc.workOrders.getWorkTrades.useQuery(
    { projectId: Number(activeProj?.id) },
    { enabled: !!activeProj }
  );

  const updateWorkTradeMutation = trpc.workOrders.updateWoWorkTrade.useMutation({
    onSuccess: () => {
      utils.workOrders.getOneWorkOrder.invalidate({ workOrderId: Number(workOrderId) });
    },
  });

  useEffect(() => {
    if (workOrder && workOrder.workTrade) {
      setCurrentWorkTradeId(workOrder.workTrade.id.toString());
    }
  }, [workOrder]);

  const handleWorkTradeChange = (value: string) => {
    setCurrentWorkTradeId(value);
    updateWorkTradeMutation.mutate({
      workOrderId: Number(workOrderId),
      workTradeId: Number(value),
    });
  };

  if (isWorkOrderLoading || isWorkTradesLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-2">
      <Select onValueChange={handleWorkTradeChange} value={currentWorkTradeId}>
        <SelectTrigger className="w-[200px] h-8 mt-1">
          <SelectValue placeholder="Select Work Trade" />
        </SelectTrigger>
        <SelectContent>
          {workTrades?.map((trade) => (
            <SelectItem key={trade.id} value={trade.id.toString()}>
              {trade.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
