import * as React from "react";
import { Text, View } from "@react-pdf/renderer";
import { useJkrPataWorkOrder } from "..";

export function ComplaintInfoSection() {
  const workOrder = useJkrPataWorkOrder();

  return (
    <View style={{ border: "1px solid black" }} wrap={false}>
      <View style={{ backgroundColor: "#f0f0f0", padding: 4 }}>
        <Text style={{ fontSize: 9, fontWeight: "bold" }}>A. Maklumat Aduan</Text>
      </View>
      <View style={{ padding: 8, gap: 6 }}>
        <View style={{ flexDirection: "row", gap: 4 }}>
          <Text style={{ fontSize: 9 }}>No Aduan:</Text>
          <Text style={{ fontSize: 9, fontWeight: "bold" }}>
            {workOrder.workRequests?.[0]?.trackingCode ?? "_________________"}
          </Text>
        </View>
        <View style={{ flexDirection: "row", gap: 4 }}>
          <Text style={{ fontSize: 9 }}>Nama Pengadu:</Text>
          <Text style={{ fontSize: 9, fontWeight: "bold" }}>
            {workOrder.workRequests?.[0]?.name ?? "_________________"}
          </Text>
        </View>
        <View style={{ flexDirection: "row", gap: 4 }}>
          <Text style={{ fontSize: 9 }}>Jenis Kerja:</Text>
          <Text style={{ fontSize: 9, fontWeight: "bold" }}>
            {workOrder.defectType?.name ?? "_________________"}
          </Text>
        </View>
        <View style={{ flexDirection: "row", gap: 4 }}>
          <Text style={{ fontSize: 9 }}>Kategori Kerja:</Text>
          <Text style={{ fontSize: 9, fontWeight: "bold" }}>
            {workOrder.workPriority?.name ?? "_________________"}
          </Text>
        </View>
        <View style={{ flexDirection: "row", gap: 4 }}>
          <Text style={{ fontSize: 9 }}>Lokasi:</Text>
          <Text style={{ fontSize: 9, fontWeight: "bold" }}>
            {workOrder.location?.name ?? "_________________"}
          </Text>
        </View>
        <View>
          <Text style={{ fontSize: 9 }}>Keterangan Kerosakan:</Text>
          <View style={{ border: "1px solid black", minHeight: 60, padding: 4, marginTop: 4 }}>
            <Text style={{ fontSize: 9, fontWeight: "bold" }}>
              {workOrder.workRequests?.[0]?.title ?? ""}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}
