import * as React from "react";
import { Text, View } from "@react-pdf/renderer";

export function ContractorSection() {
  return (
    <View style={{ border: "1px solid black" }} wrap={false}>
      <View style={{ backgroundColor: "#f0f0f0", padding: 4 }}>
        <Text style={{ fontSize: 9, fontWeight: "bold" }}>
          E. Lantikan Kontraktor / Tempoh Tanggungan Kecacatan (Jika Berkenaan)
        </Text>
      </View>
      <View style={{ padding: 8, gap: 6 }}>
        <View style={{ flexDirection: "row", gap: 4 }}>
          <Text style={{ fontSize: 9 }}>Nama Kontraktor:</Text>
          <Text style={{ fontSize: 9, flex: 1, fontWeight: "bold" }}>
            _________________
          </Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <View style={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <Text style={{ fontSize: 9 }}>Lantikan Mula: </Text>
            <Text style={{ fontSize: 9, fontWeight: "bold" }}>
              _________________
            </Text>
          </View>
          <View style={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <Text style={{ fontSize: 9 }}>Kos Akhir: </Text>
            <Text style={{ fontSize: 9, fontWeight: "bold" }}>
              _________________
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row" }}>
          <View style={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <Text style={{ fontSize: 9 }}>Tarikh Siap Kerja: </Text>
            <Text style={{ fontSize: 9, fontWeight: "bold" }}>
              _________________
            </Text>
          </View>
          <View style={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <Text style={{ fontSize: 9 }}>Tempoh Tanggungan: </Text>
            <Text style={{ fontSize: 9, fontWeight: "bold" }}>
              _________________
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}
