import { PDFViewer } from "@react-pdf/renderer";
import { useAppSelector } from "../../redux/store";
import { JRKWorkRequestQr } from "@cerev-cmms/pdf";
import * as QRCode from "qrcode";
import { useQuery } from "@tanstack/react-query";
import { Loader2, AlertCircle } from "lucide-react";
import AppButton from "../../components/AppButton";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";

export default function JKRWorkRequestQrCode() {
  const originUrl = window.location.origin;
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const qrCodeUrl = `${originUrl}/public/work-request/${activeProj?.workRequestCode}/request`;

  const generateQRCode = async () => {
    return await QRCode.toDataURL(qrCodeUrl);
  };

  const {
    data: qrCode,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["qrCode"],
    queryFn: generateQRCode,
  });

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <Loader2 className="h-16 w-16 animate-spin text-primary-900" />
        <p className="text-xl mt-4 text-slate-400">Please wait, System is generating QR Code...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Card className="w-96">
          <CardHeader>
            <CardTitle className="flex items-center text-destructive">
              <AlertCircle className="mr-2" />
              Error generating QR Code
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="mb-4">
              {error instanceof Error ? error.message : "An unknown error occurred"}
            </p>
            <AppButton
              label="Try Again"
              onClick={() => refetch()}
              className="w-full"
            />
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <PDFViewer style={{ height: "100vh", width: "100vw" }}>
      <JRKWorkRequestQr qrCodeUrl={qrCode} />
    </PDFViewer>
  );
}
