import { ContractMetrics } from "./ContractMetrics";
import { ContractPieChart } from "./ContractPieChart";

export default function LegalComplianceAnalytics() {
  return (
    <div className="space-y-4">
      <h2 className="text-xl font-bold">Legal Compliance Overview</h2>
      <ContractMetrics />
      <ContractPieChart />
    </div>
  );
}
