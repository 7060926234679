import * as React from "react";
import { Text, View } from "@react-pdf/renderer";
import { useJkrPataWorkOrder } from "..";

export function ReferenceBox() {
  const workOrder = useJkrPataWorkOrder();

  return (
    <View style={{ border: "1px solid black", padding: 8 }} wrap={false}>
      <View style={{ flexDirection: "row", gap: 4}}>
        <Text style={{ fontSize: 9 }}>No Ruj:</Text>
        <Text style={{ fontSize: 9, flex: 1, fontWeight: "bold" }}>
          {workOrder.refNo ?? "_________________"}
        </Text>
      </View>
      <View style={{ flexDirection: "row", gap: 4, marginTop: 4 }}>
        <Text style={{ fontSize: 9 }}>Status:</Text>
        <Text style={{ fontSize: 9, flex: 1, fontWeight: "bold" }}>
          {workOrder.defectStatus?.name ?? "_________________"}
        </Text>
      </View>
    </View>
  );
}
