import { z } from "zod";

// Define Zod schemas for PolicyStatus and PolicyType based on the Prisma schema
export const PolicyStatusSchema = z.enum([
  "ALLOWED",
  "NOT_ALLOWED",
]);

export const PolicyTypeSchema = z.enum([
  "ADJUST_INVENTORY",
  "CHECK_WORK_ORDER_PRICING",
  "WORK_ORDER_EDIT_AFTER_CLOSE",
  "VIEW_PRIVATE_ATTACHMENT",
  "ESCALATION_ACCESS",
  "DELETE_WORK_ORDER_COMMENT",
]);

// Update the existing schemas to use the new Zod types
export const checkOneUserPolicySchema = z.object({
  projectId: z.string(),
  policyName: PolicyTypeSchema,
  userId: z.number(),
});

export type CheckOneUserPolicyInput = z.infer<typeof checkOneUserPolicySchema>;

export const updateUserPolicySchema = z.object({
  projectId: z.string(),
  policyName: PolicyTypeSchema,
  policyStatus: PolicyStatusSchema,
  userId: z.number(),
});

export type UpdateUserPolicyInput = z.infer<typeof updateUserPolicySchema>;

export const getPolicySchema = z.object({});

export type GetPolicyInput = z.infer<typeof getPolicySchema>;

// Export types for PolicyStatus and PolicyType
export type PolicyStatus = z.infer<typeof PolicyStatusSchema>;
export type PolicyType = z.infer<typeof PolicyTypeSchema>;
