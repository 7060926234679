import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { httpBatchLink, TRPCClientError } from "@trpc/client";
import { useState } from "react";
import { toast } from "sonner";
import SuperJSON from "superjson";
import { auth } from "../firebase/firebaseConfig";
import { trpc } from "../lib/trpc";

export default function AppQueryClientProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: `${import.meta.env.VITE_SERVER_V2_API_URL}/trpc`,
          // You can pass any HTTP headers you wish here
          async headers() {
            const result = { Authorization: "" };

            const token = await auth.currentUser?.getIdToken();
            if (token) result.Authorization = `Bearer ${token}`;

            return result;
          },
          transformer: SuperJSON,
        }),
      ],
    })
  );

  const [appQueryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          retry: 2,
        },
      },
      queryCache: new QueryCache({
        onError: (err) => {
          toast.error("Error", {
            description:
              (err as any)?.response?.data?.message ??
              "Sorry, something went wrong",
          });
        },
      }),
      mutationCache: new MutationCache({
        onError: (err) => {
          if (err instanceof TRPCClientError) {
            return toast.error(err.message ?? "Sorry, something went wrong");
          }
          toast.error("Error", {
            description:
              (err as any)?.response?.data?.message ??
              "Sorry, something went wrong",
          });
        },
      }),
    })
  );

  return (
    <trpc.Provider client={trpcClient} queryClient={appQueryClient}>
      <QueryClientProvider client={appQueryClient}>
        {children}
      </QueryClientProvider>
    </trpc.Provider>
  );
}
