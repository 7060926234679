import React, { useState } from "react";
import { ChevronRight, ChevronDown, Plus } from "lucide-react";
import AppButton from "../../../components/AppButton";
import { useSearchParams } from "react-router-dom";
import {
  LOCATION_FORM_DRAWER,
  DRAWER_CREATE_STATE,
} from "../../../components/drawers/AppDrawer";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setSelectedLocation } from "../../../redux/slices/Asset/AssetSlice";
import { useLocationControllerGetLocation } from "../../../api/location/location";
import {
  AssetLocationSorting,
  useAssetLocationSorting,
} from "../../../stores/assetLocationSortingStore/assetLocationSortingStore";
import { cn } from "../../../utils/util";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "../../../components/ui/context-menu";

interface LocationTreeItemProps {
  loc: any; // Replace with the correct type
  level: number;
  projectId: string;
}

export default function LocationTreeItem({
  loc,
  level,
  projectId,
}: LocationTreeItemProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const sortingMode = useAssetLocationSorting();
  const isSorted = sortingMode === AssetLocationSorting.ALPHABETICAL;
  const selectedLocation = useAppSelector(
    (state) => state.asset.selectedLocation
  );

  const { data: childLocations } = useLocationControllerGetLocation(
    {
      projectId: projectId,
      locationId: loc.id.toString(),
    },
    {
      query: {
        enabled: isExpanded,
        select: (res) =>
          res.data.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)),
        staleTime: Infinity,
      },
    }
  );

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleAddLocation = () => {
    setSearchParams((p) => {
      p.set("drawerType", LOCATION_FORM_DRAWER);
      p.set("drawerState", DRAWER_CREATE_STATE);
      p.set("parentLocId", loc.id.toString());
      return p;
    });
  };

  return (
    <div>
      <ContextMenu>
        <ContextMenuTrigger>
          <div
            className={cn(
              "flex items-center",
              selectedLocation?.id === loc.id && "bg-primary-200 rounded-md"
            )}
          >
            <AppButton
              icon={
                isExpanded ? (
                  <ChevronDown size={16} />
                ) : (
                  <ChevronRight size={16} />
                )
              }
              variant="ghost"
              className="p-1"
              onClick={handleToggle}
            />
            <button
              className={cn(
                "flex-grow text-left p-2 rounded-md transition-colors duration-200",
                selectedLocation?.id === loc.id && "bg-primary-200 rounded-md"
              )}
              onClick={() => dispatch(setSelectedLocation(loc))}
            >
              <p className="truncate max-w-[275px]">
                {loc.name}
              </p>
            </button>
          </div>
        </ContextMenuTrigger>
        <ContextMenuContent>
          <ContextMenuItem onClick={handleAddLocation}>
            <Plus className="mr-2 h-4 w-4" />
            Add Location
          </ContextMenuItem>
        </ContextMenuContent>
      </ContextMenu>
      {childLocations && (
        <div
          className={cn(
            "ml-4 transition-all duration-300",
            isExpanded
              ? "opacity-100 max-h-1000 overflow-hidden"
              : "opacity-0 max-h-0 overflow-hidden"
          )}
        >
          {childLocations
            .sort(isSorted ? (a, b) => a.name.localeCompare(b.name) : () => 0)
            .map((childLoc) => (
              <LocationTreeItem
                key={childLoc.id}
                loc={childLoc}
                level={level + 1}
                projectId={projectId}
              />
            ))}
        </div>
      )}
    </div>
  );
}
