import { trpc } from "../../../../lib/trpc";
import { useAppSelector } from "../../../../redux/store";
import { PmStatusCountCard } from "./PmStatusCountCard";

export function PmStatusCountSection() {
  const project = useAppSelector((state) => state.root.activeProject);
  const { data } = trpc.pm.getPmChecklistStatus.useQuery({
    projectId: project?.id ?? 0,
  });

  return (
    <>
      {data?.map((statusCount) => (
        <PmStatusCountCard key={statusCount.id} statusCount={statusCount} />
      ))}
    </>
  );
}
