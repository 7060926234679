import { z } from "zod";

export const getStraightLineDepreciationInput = z.object({
  equipmentId: z.number(),
  projectId: z.number(),
});

export type GetStraightLineDepreciationInput = z.infer<
  typeof getStraightLineDepreciationInput
>;

export const getEquipmentMaintenanceCostInput = z.object({
  equipmentId: z.number(),
  projectId: z.number(),
});

export type GetEquipmentMaintenanceCostInput = z.infer<
  typeof getEquipmentMaintenanceCostInput
>;

export const GetDepreciationVsMaintenanceCostInput = z.object({
    equipmentId: z.number(),
    projectId: z.number(),
  });

export type GetDepreciationVsMaintenanceCostInput = z.infer<
  typeof GetDepreciationVsMaintenanceCostInput
>;
