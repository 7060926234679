import { PmChecklistStatusCount } from "@cerev-cmms/zod-types";

interface PmStatusCountCardProps {
  statusCount: PmChecklistStatusCount;
}

export function PmStatusCountCard({ statusCount }: PmStatusCountCardProps) {
  return (
    <div
      className="rounded-lg p-4 border"
      style={{
        borderLeft: `4px solid ${statusCount.colorHex || "#CBD5E0"}`,
      }}
    >
      <div className="flex flex-col">
        <span className="text-3xl font-bold text-gray-800">
          {statusCount.count}
        </span>
        <span className="mt-1 text-sm text-gray-600">
          {statusCount.name} PM checklist{statusCount.count !== 1 ? "s" : ""}
        </span>
      </div>
    </div>
  );
}
