import { RouterOutputs } from "@cerev-cmms/trpc/src/lib/trpc/root";
import { useAssetControllerGetAssetPm } from "../../../../api/assets/assets";
import AppCenterEmpty from "../../../AppCenterEmpty";
import DrawerFormSkeleton from "../../../skeletons/DrawerFormSkeleton";
import PmChecklistItem from "../../pm/tabs/components/PmChecklistItem";

interface AssetPmTabProps {
  asset?: RouterOutputs["assets"]["getOneAsset"];
}

export default function AssetPmTab({ asset }: AssetPmTabProps) {
  const { data: pmChecklists, isLoading } = useAssetControllerGetAssetPm(
    asset?.id.toString() ?? "",
    {
      cursorId: undefined,
    },
    {
      query: {
        enabled: !!asset,
        select: (res) => res.data,
      },
    }
  );

  if (isLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-4">
      {pmChecklists?.length === 0 && <AppCenterEmpty />}
      {pmChecklists?.map((pm) => (
        <PmChecklistItem pmChecklist={pm} key={pm.id} />
      ))}
    </div>
  );
}
