import React from "react";
import { TabsList, TabsTrigger, TabsContent } from "../../../components/ui/tabs";
import AppIFrame from "./AppIframe";

interface DefaultDashboardProps {
  data: any[];
  currentTabIdx: string;
}

const DefaultDashboard: React.FC<DefaultDashboardProps> = ({ data, currentTabIdx }) => {
  return (
    <>
      <TabsList>
        {data.map((an, idx) => (
          <TabsTrigger key={an.iframeUrl} value={idx.toString()}>
            <p>{an.settings.tabName}</p>
          </TabsTrigger>
        ))}
      </TabsList>
      {data.map((an, idx) => (
        <TabsContent key={an.iframeUrl} value={idx.toString()}>
          <AppIFrame
            analyticRes={an}
            tabIdx={Number(currentTabIdx)}
            idx={idx}
            key={an.iframeUrl}
          />
        </TabsContent>
      ))}
    </>
  );
};

export default DefaultDashboard;
