import { Error } from "@mui/icons-material";
import { CerevModule } from "../../api/model";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import ModuleAccessGuard from "../../guards/ModuleAccessGuard";
import useAppTabsNavigation from "../../hooks/useAppTabsNavigation";
import { trpc } from "../../lib/trpc";
import { useAppSelector } from "../../redux/store";
import AppTitle from "../HomeScreen/components/AppTitle";
import KDNPDashboard from "./components/KDNPDashboard";
import DefaultDashboard from "./components/DefaultDashboard";

export default function DashboardScreen() {
  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "dashboardTabIdx",
    defaultValue: "0",
  });
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data, isLoading } = trpc.analytics.getAnalyticsReport.useQuery(
    {
      projectId: activeProj?.id ?? 0,
    },
    {
      enabled: !!activeProj,
    }
  );

  if (!data || isLoading) return <div>Loading...</div>;

  if (data.length === 0 && activeProj?.code !== "KDNP")
    return (
      <>
        <AppTitle title="Dashboard" />
        <div className="h-full w-full flex justify-center items-center">
          <div className="flex flex-col items-center">
            <Error className="text-neutral-500 w-20 h-20" />
            <p className="text-neutral-500 mt-4">
              Unfortunately, there is no analytics setup for this project
            </p>
          </div>
        </div>
      </>
    );

  return (
    <ModuleAccessGuard module={CerevModule.DASHBOARD}>
      <AppTitle title="Dashboard" />
      <Tabs value={currentTabIdx} onValueChange={onTabChange}>
        {activeProj?.code === "KDNP" ? (
          <KDNPDashboard currentTabIdx={currentTabIdx} />
        ) : (
          <DefaultDashboard data={data} currentTabIdx={currentTabIdx} />
        )}
      </Tabs>
    </ModuleAccessGuard>
  );
}
