import React from "react";
import { cn } from "../utils/util";
import { Button, ButtonProps } from "./ui/button";
import { Loader2 } from "lucide-react";

interface AppButtonProps extends ButtonProps {
  label?: string;
  className?: string;
  isLoading?: boolean;
  icon?: React.ReactNode;
  disabled?: boolean;
}

export default function AppButton({
  label,
  className,
  isLoading,
  icon,
  disabled,
  ...props
}: AppButtonProps) {
  return (
    <Button
      {...props}
      disabled={isLoading || disabled}
      className={cn(
        "hover:bg-slate-500 duration-200 transition-all flex gap-3 items-center",
        {
          "bg-primary-900":
            props.variant !== "outline" &&
            props.variant !== "destructive" &&
            props.variant !== "ghost",
          "text-primary-900 hover:bg-primary-100 hover:text-primary-900":
            props.variant === "outline",
          "bg-slate-200": isLoading || disabled,
          "hover:bg-slate-50": props.variant === "ghost",
        },
        className
      )}
    >
      {isLoading && <Loader2 className="h-6 w-6 animate-spin text-slate-800" />}
      {!isLoading && (
        <div className="flex gap-4 items-center">
          {icon}
          {label}
        </div>
      )}
      {!isLoading && props.children}
    </Button>
  );
}
