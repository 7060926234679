import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";
import { useAssetTypeControllerGetAssetType } from "../../../api/asset-type/asset-type";
import {
  useLocationControllerGetFullLocationList,
  useLocationControllerGetOneLocation,
} from "../../../api/location/location";
import useAppStorage from "../../../hooks/useAppStorage";
import useIsUpdateDrawerState from "../../../hooks/useDrawerState";
import { useGetUserDataQuery } from "../../../redux/slices/Auth/AuthApi";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { publicWorkRequestLocationColumns } from "../../../screens/PublicWorkRequestScreen/PublicWorkRequestFormScreen/columns/publicWorkRequestLocationColumns";
import AppButton from "../../AppButton";
import AppCameraFieldFilesTRPC from "../../AppCameraFieldFilesTRPC";
import { AppDatePicker } from "../../AppDatePicker";
import AppDocumentUploadTRPC from "../../AppDocumentUploadTRPC";
import AppTextAreaField from "../../AppTextAreaField";
import AppTextField from "../../AppTextField";
import AppSelectWithDialog from "../../dialogs/AppSelectWithDialog/AppSelectWithDialog";
import ConfirmDeleteBtnWithDialog from "../../dialogs/ConfirmDeleteWithTextDialog";
import { assetTypeColumns } from "./columns/assetTypeColumns";
import AppDocumentField from "../../AppDocumentField";
import { ASSET_DETAIL_DRAWER } from "../AppDrawer";
import { trpc } from "../../../lib/trpc";
import { RouterOutputs } from "@cerev-cmms/trpc";

type Attachment = RouterOutputs["assets"]["getOneAsset"]["photos"][number];

const AssetFormSchema = z.object({
  name: z.string().min(1, { message: "Please input a name" }),
  assetCode: z.string().min(1, { message: "Please input an equipment code" }),
  assetType: z.object(
    {
      id: z.number(),
      name: z.string(),
    },
    { required_error: "Please select an equipment type" }
  ),
  description: z.string().optional().default(""),
  photos: z.instanceof(File).array().optional(),
  additionalDocuments: z.instanceof(File).array().optional(),
  model: z.string().optional(),
  capacity: z.string().optional(),
  serialNo: z.string().optional(),
  inverterModel: z.string().optional(),
  motorCapacity: z.string().optional(),
  brand: z.string().optional(),
  location: z.object(
    {
      id: z.number(),
      name: z.string(),
    },
    { required_error: "Please select a location" }
  ),
  installationDate: z.date().optional().nullable(),
  warrantyDate: z.date().optional().nullable(),
});

export type AssetForm = z.infer<typeof AssetFormSchema>;

export default function AssetFormDrawer() {
  const qc = useQueryClient();
  const isUpdateDrawer = useIsUpdateDrawerState();
  const [searchParams, setSearchParams] = useSearchParams();
  const locationId = searchParams.get("locationId");
  const assetId = searchParams.get("assetId");
  const parentAssetId = searchParams.get("parentAssetId");
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const utils = trpc.useUtils();

  const { data: parentAsset } = trpc.assets.getOneAsset.useQuery(
    {
      assetId: Number(parentAssetId),
      projectId: Number(activeProj?.id),
    },
    {
      enabled: !!parentAssetId && !!activeProj,
    }
  );
  const { mutateAsync: deleteAsset } = trpc.assets.deleteAsset.useMutation({
    onSuccess: () => {
      toast.success("Equipment deleted successfully");
      qc.invalidateQueries({
        predicate: (qry) => (qry.queryKey[0] as string).includes("asset"),
      });
      if (parentAssetId || editAsset?.parentAssetId) {
        setSearchParams((p) => {
          p.set("drawerType", ASSET_DETAIL_DRAWER);
          p.delete("drawerState");
          p.delete("parentAssetId");
          if (editAsset?.parentAssetId) {
            p.set("assetId", editAsset?.parentAssetId?.toString());
            p.set("assetDetailTabIdx", "3");
          }
          return p;
        });
      } else {
        setSearchParams(new URLSearchParams());
      }
    },
  });
  const [uploadedPhotosAsset, setUploadedPhotosAsset] = useState<Attachment[]>(
    []
  );
  const [uploadedDocsAsset, setUploadedDocsAsset] = useState<Attachment[]>([]);
  const dispatch = useAppDispatch();
  const methods = useForm<AssetForm>({
    resolver: zodResolver(AssetFormSchema),
    defaultValues: {
      name: "",
      assetCode: "",
      assetType: undefined,
      description: "",
      photos: [],
      additionalDocuments: [],
      model: "",
      serialNo: "",
      inverterModel: "",
      motorCapacity: "",
      brand: "",
      location: parentAssetId
        ? {
            id: parentAsset?.location?.id ?? 0,
            name: parentAsset?.location?.name ?? "",
          }
        : undefined,
    },
  });

  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const { data: user } = useGetUserDataQuery();

  const { data: currentLoc } = useLocationControllerGetOneLocation(
    locationId ?? "",
    {
      query: {
        enabled: !!locationId,
        select: (res) => res.data,
      },
    }
  );

  const { data: fullLocList } = useLocationControllerGetFullLocationList(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj,
        select: (res) => res.data,
      },
    }
  );

  const { data: editAsset } = trpc.assets.getOneAsset.useQuery(
    {
      assetId: Number(assetId),
      projectId: Number(activeProj?.id),
    },
    {
      enabled: !!assetId && !!activeProj,
    }
  );

  const { data: assetTypes } = useAssetTypeControllerGetAssetType(
    {
      companyId: activeComp?.id.toString() ?? "",
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeComp && !!activeProj,
        select: (res) => res.data,
      },
    }
  );

  const { mutateAsync: createAsset } = trpc.assets.createAsset.useMutation();
  const { mutateAsync: updateAsset } = trpc.assets.updateAsset.useMutation();
  const { mutateAsync: createSubAsset } =
    trpc.assets.createSubAsset.useMutation();
  const { useUploadAttachmentMutation } = useAppStorage();
  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      qc.invalidateQueries({
        predicate: (qry) => (qry.queryKey[0] as string).includes("asset"),
      });
      utils.assets.getOneAsset.invalidate();

      toast.success("Record Added / Updated");

      if (parentAssetId || editAsset?.parentAssetId) {
        setSearchParams((p) => {
          p.set("drawerType", ASSET_DETAIL_DRAWER);
          p.delete("drawerState");
          p.delete("parentAssetId");
          if (parentAssetId) p.set("assetId", parentAssetId);
          return p;
        });
      } else {
        if (!assetId) return setSearchParams(new URLSearchParams());
        setSearchParams((p) => {
          p.set("drawerType", ASSET_DETAIL_DRAWER);
          p.set("assetId", editAsset?.id.toString() ?? "");
          p.delete("drawerState");
          return p;
        });
      }
    },
  });

  const { mutateAsync: deleteDocument } =
    trpc.assets.deleteAttachment.useMutation();
  const { mutateAsync: deletePhoto } = trpc.assets.deletePhoto.useMutation();

  const onDeleteUploadedDoc = async (att: Attachment) => {
    const newUploadedDocs = uploadedDocsAsset.filter((v) => v.id !== att.id);
    if (!editAsset) return;
    deleteDocument({
      attachmentId: att?.id ?? 0,
      assetId: editAsset.id,
    });
    setUploadedDocsAsset(newUploadedDocs);
  };

  const onDeleteUploadedPhoto = async (att: Attachment) => {
    const newUploadedPhotos = uploadedPhotosAsset.filter(
      (v) => v.id !== att.id
    );
    if (!editAsset) return;
    deletePhoto({
      attachmentId: att?.id ?? 0,
      assetId: editAsset.id,
    });
    setUploadedPhotosAsset(newUploadedPhotos);
  };

  const onSubmit: SubmitHandler<AssetForm> = async (data) => {
    if (!activeProj || !user) return;
    await mutate({
      files: data.photos ?? [],
      secondFiles: data.additionalDocuments ?? [],
      mutateAsync: async (pho, docs) => {
        if (isUpdateDrawer) {
          if (!editAsset) return;
          return await updateAsset({
            assetId: editAsset.id,
            name: data.name,
            assetCode: data.assetCode,
            description: data.description,
            photos:
              pho?.map((p) => ({
                ...p,
                underProject: {
                  id: activeProj?.id ?? 0,
                },
              })) ?? [],
            additionalDocuments:
              docs?.map((d) => ({
                ...d,
                underProject: {
                  id: activeProj?.id ?? 0,
                },
              })) ?? [],
            locationId: data.location.id,
            projectId: activeProj?.id ?? 0,
            assetTypeId: data.assetType.id,
            updatedById: user.id,
            model: data.model,
            serialNo: data.serialNo,
            inverterModel: data.inverterModel,
            motorCapacity: data.motorCapacity,
            capacity: data.capacity,
            brand: data.brand,
            installationDate: data.installationDate,
            warrantyDate: data.warrantyDate,
          });
        }

        // Check if it is subasset being created
        if (parentAssetId) {
          if (!parentAsset?.location?.id) {
            toast.error("Parent asset location not found");
            return;
          }
          return await createSubAsset({
            parentAssetId: Number(parentAssetId),
            code: data.assetCode,
            name: data.name,
            projectId: activeProj?.id ?? 0,
            locationId: parentAsset?.location?.id ?? 0,
            description: data.description,
            photos:
              pho?.map((p) => ({
                ...p,
                underProject: {
                  id: activeProj?.id ?? 0,
                },
              })) ?? [],
            additionalDocuments:
              docs?.map((d) => ({
                ...d,
                underProject: {
                  id: activeProj?.id ?? 0,
                },
              })) ?? [],
            assetTypeId: data.assetType.id,
            model: data.model,
            serialNo: data.serialNo,
            inverterModel: data.inverterModel,
            motorCapacity: data.motorCapacity,
            capacity: data.capacity,
            brand: data.brand,
            installationDate: data.installationDate,
            warrantyDate: data.warrantyDate,
          });
        }

        return await createAsset({
          name: data.name,
          assetCode: data.assetCode,
          description: data.description,
          photos:
            pho?.map((p) => ({
              ...p,
              underProject: {
                id: activeProj?.id ?? 0,
              },
            })) ?? [],
          additionalDocuments:
            docs?.map((d) => ({
              ...d,
              underProject: {
                id: activeProj?.id ?? 0,
              },
            })) ?? [],
          locationId: data.location.id,
          projectId: activeProj?.id ?? 0,
          assetTypeId: data.assetType.id,
          createdById: user.id,
          model: data.model,
          serialNo: data.serialNo,
          inverterModel: data.inverterModel,
          motorCapacity: data.motorCapacity,
          capacity: data.capacity,
          brand: data.brand,
          installationDate: data.installationDate,
          warrantyDate: data.warrantyDate,
        });
      },
    });
  };

  // is create mode, auto select drawer for user
  useEffect(() => {
    if (currentLoc)
      methods.setValue("location", {
        id: currentLoc.id,
        name: currentLoc.name,
      });
  }, [currentLoc]);

  // is edit mode
  useEffect(() => {
    if (editAsset && isUpdateDrawer && !methods.formState.isDirty) {
      methods.setValue("name", editAsset.name ?? "");
      methods.setValue("assetCode", editAsset.code ?? "");
      methods.setValue("description", editAsset.description ?? "");
      methods.setValue("assetType", {
        id: editAsset.assetType?.id ?? 0,
        name: editAsset.assetType?.name ?? "",
      });
      methods.setValue("model", editAsset.model ?? "");
      methods.setValue("serialNo", editAsset.serialNo ?? "");
      methods.setValue("inverterModel", editAsset.inverterModel ?? "");
      methods.setValue("motorCapacity", editAsset.motorCapacity ?? "");
      methods.setValue("brand", editAsset.brand ?? "");
      methods.setValue("location", {
        id: editAsset?.location?.id ?? 0,
        name: editAsset?.location?.name ?? "",
      });
      setUploadedPhotosAsset(editAsset.photos ?? []);
      setUploadedDocsAsset(editAsset.additionalDocuments ?? []);
      if (editAsset.installationDate)
        methods.setValue(
          "installationDate",
          new Date(editAsset.installationDate)
        );
      if (editAsset.warrantyDate)
        methods.setValue(
          "warrantyDate",
          new Date(editAsset.warrantyDate)
        );
    }
  }, [editAsset, searchParams]);

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">
          {isUpdateDrawer ? "Update" : "Create"} Equipment
        </p>
        <AppTextField label="Name" name="name" />
        <AppTextField label="Code" name="assetCode" />
        <AppTextField label="Model No." name="model" />
        <AppTextField label="Serial No." name="serialNo" />
        <AppTextField label="Brand" name="brand" />
        <AppTextField label="Inverter Model (if any)" name="inverterModel" />
        <AppTextField label="Motor Capacity" name="motorCapacity" />
        <AppDatePicker label="Installation Date" name="installationDate" />
        <AppDatePicker
          label="Warranty Expiry Date"
          name="warrantyDate"
          disabled={(date) => false}
        />
        <AppSelectWithDialog
          label="Equipment Type"
          columns={assetTypeColumns}
          placeholder="Select an Equipment Type"
          defaultValue={undefined}
          control={methods.control}
          name="assetType"
          items={assetTypes ?? []}
          onResultRender={(item, idx) => (
            <div className="font-medium">{item?.name ?? "-"}</div>
          )}
          onOptionsRender={(item, idx) => (
            <div className="font-medium">{item?.name ?? "-"}</div>
          )}
          dialogTitle="Select Equipment Type"
          error={!!methods.formState.errors.assetType?.message}
          helperText={methods.formState.errors.assetType?.message}
        />
        {/* Do not show select location when creating children asset, it does not make sense */}
        {!parentAsset && (
          <AppSelectWithDialog
            label="Select Location"
            columns={publicWorkRequestLocationColumns}
            placeholder="Select Location"
            control={methods.control}
            defaultValue={undefined}
            name="location"
            items={fullLocList ?? []}
            onResultRender={(item, idx) => (
              <div className="font-medium">{item?.name ?? "-"}</div>
            )}
            onOptionsRender={(item, idx) => (
              <div className="font-medium">{item?.name ?? "-"}</div>
            )}
            dialogTitle="Select Location"
            error={!!methods.formState.errors.location?.message}
            helperText={methods.formState.errors.location?.message}
          />
        )}

        <AppTextAreaField label="Description" name="description" />
        <Controller
          control={methods.control}
          name="photos"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AppCameraFieldFilesTRPC
                uploadedPhotos={uploadedPhotosAsset}
                onDeleteUploadedPhoto={onDeleteUploadedPhoto}
                label="Photos"
                onChange={onChange}
                onDelete={(url) => {
                  if (!value) return;
                  const newSetPhotos = value.filter((v) => v !== url);
                  onChange(newSetPhotos);
                }}
                photos={value ?? []}
                error={!!error}
                helperText={
                  methods.formState.errors.photos?.message !== ""
                    ? "At least one photo required"
                    : ""
                }
              />
            );
          }}
        />
        <Controller
          control={methods.control}
          name="additionalDocuments"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AppDocumentUploadTRPC
                uploadedDocs={uploadedDocsAsset}
                onDeleteUploadedDoc={onDeleteUploadedDoc}
                label="Documents"
                onChange={onChange}
                onDelete={(url) => {
                  if (!value) return;
                  const newFiles = value.filter((v) => v !== url);
                  onChange(newFiles);
                }}
                files={value ?? []}
                error={!!error}
                helperText={
                  methods.formState.errors.additionalDocuments?.message !== ""
                    ? "At least one file required"
                    : ""
                }
              />
            );
          }}
        />
        <div className="flex gap-4">
          <AppButton
            isLoading={isPending}
            label={isUpdateDrawer ? "Update" : "Create"}
            onClick={methods.handleSubmit(onSubmit)}
          />
          {editAsset && !parentAssetId && (
            <ConfirmDeleteBtnWithDialog
              confirmDeleteTxt={editAsset?.name ?? ""}
              onDeleteConfirm={async () => {
                await deleteAsset({
                  assetId: editAsset?.id ?? 0,
                });
              }}
            />
          )}
        </div>
      </div>
    </FormProvider>
  );
}
