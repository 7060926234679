import { useFormContext, useWatch } from "react-hook-form";
import { WorkRequestForm } from "../components/PublicWorkRequestForm";
import {
  WorkRequestSetting,
  WorkRequestSettingAssetRequired,
} from "../../../../api/model";
import { useEffect, useState } from "react";
import { trpc } from "../../../../lib/trpc";
import { useParams } from "react-router-dom";

interface AssetSelectionWithDialogProps {
  workRequestSetting?: WorkRequestSetting;
}

export default function AssetSelectionWithDialog({
  workRequestSetting,
}: AssetSelectionWithDialogProps) {
  const methods = useFormContext<WorkRequestForm>();
  const { projectCode } = useParams();

  const asset = useWatch({ control: methods.control, name: "asset" });

  const { data: publicAssetDetail, isLoading } = trpc.assets.getPublicAssetDetail.useQuery(
    { assetId: asset?.id, projectCode: projectCode ?? "" },
    {
      enabled: !!asset?.id && !!projectCode,
    }
  );


  if (!publicAssetDetail || !workRequestSetting) return <></>;

  return (
    <div className="space-y-1">
      <p className="font-sans font-medium text-sm">Equipment</p>
      {isLoading ? (
        <div className="px-3 py-2 font-sans text-gray-500">Loading...</div>
      ) : publicAssetDetail ? (
        <div className="px-3 py-2 font-sans text-white font-medium flex w-full bg-primary-900 rounded-md flex-col">
          <p>{publicAssetDetail.assetName}</p>
          <p className="font-thin text-sm">{publicAssetDetail.assetCode}</p>
        </div>
      ) : (
        <div className="px-3 py-2 font-sans text-gray-500">Asset not found</div>
      )}
    </div>
  );
}
