import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import AppTextField from "@/components/AppTextField";
import AppButton from "@/components/AppButton";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

interface DepreciationRateDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onUpdate: (value: number) => void;
  currentValue?: number;
}

export default function DepreciationRateDialog({
  open,
  onOpenChange,
  onUpdate,
  currentValue,
}: DepreciationRateDialogProps) {
  const methods = useForm<{ depreciationRate: number }>({
    defaultValues: { depreciationRate: currentValue },
    resolver: zodResolver(
      z.object({ depreciationRate: z.coerce.number().min(0) })
    ),
  });

  const onSubmit: SubmitHandler<{ depreciationRate: number }> = (data) => {
    onUpdate(data.depreciationRate);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Depreciation Rate</DialogTitle>
        </DialogHeader>
        <FormProvider {...methods}>
          <AppTextField
            name="depreciationRate"
            label="Depreciation Rate"
            type="number"
            step="0.01"
          />
        </FormProvider>
        <DialogFooter>
          <AppButton variant="ghost" onClick={() => onOpenChange(false)}>
            Cancel
          </AppButton>
          <AppButton onClick={methods.handleSubmit(onSubmit)}>Save</AppButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
