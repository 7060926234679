import * as React from "react";
import { Page, Text, View } from "@react-pdf/renderer";
import { HeaderForm } from "./HeaderForm";
import { NotesSection } from "./NotesSection";
import { FooterSection } from "./FooterSection";
import { FooterPageNumber } from "./FooterPageNumber";

const Checkbox = () => (
  <View
    style={{
      width: 12,
      height: 12,
      border: "1px solid black",
      marginRight: 8,
    }}
  />
);

export function PataPage() {
  return (
    <Page
      size="A4"
      style={{
        paddingHorizontal: 90,
        paddingVertical: 20,
        flexDirection: "column",
        gap: 8,
      }}
    >
      {/* Header */}
      <View style={{ alignItems: "flex-end", marginBottom: 12 }}>
        <Text style={{ fontSize: 10 }}>JKR.PATA.F7 / 2</Text>
      </View>

      {/* Title */}
      <View style={{ alignItems: "center", marginBottom: 12 }}>
        <Text
          style={{
            fontSize: 12,
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          BORANG ADUAN / PERMINTAAN PELANGGAN
        </Text>
      </View>

      {/* Form Fields */}
      <HeaderForm />

      {/* Checkboxes Section */}
      <View style={{ marginTop: 12 }}>
        <Text style={{ fontSize: 9, marginBottom: 6 }}>
          Tick pada petak yang berkenaan & jenis kerosakan: contohnya elektrikal
        </Text>

        <View style={{ flexDirection: "row" }}>
          <View style={{ flex: 1, gap: 6 }}>
            {/* Left column checkboxes */}
            {[
              "Keselamatan",
              "Housekeeping",
              "Landskap",
              "Mekanikal",
              "Elektrik",
              "Sivil dan Struktur",
              "Parkir",
              "Lain-lain",
            ].map((label) => (
              <View key={label} style={{ flexDirection: "row", alignItems: "center" }}>
                <Checkbox />
                <Text style={{ fontSize: 9 }}>{label}</Text>
              </View>
            ))}
          </View>

          <View style={{ flex: 1, gap: 6 }}>
            {/* Right column checkboxes */}
            {[
              "Telefon",
              "Faksimile",
              "E-Mel",
              "Surat",
              "Lain-lain (Nyatakan: ________)",
            ].map((label) => (
              <View key={label} style={{ flexDirection: "row", alignItems: "center" }}>
                <Checkbox />
                <Text style={{ fontSize: 9 }}>{label}</Text>
              </View>
            ))}
          </View>
        </View>
      </View>

      {/* Priority Section */}
      <View style={{ marginTop: 12 }}>
        <Text style={{ fontSize: 9, marginBottom: 6 }}>
          Keutamaan: Tick pada tahap kerosakan yang berlaku
        </Text>
        <View style={{ gap: 6 }}>
          {["Umum", "Segera (Breakdown)", "Kecemasan"].map((label) => (
            <View key={label} style={{ flexDirection: "row", alignItems: "center" }}>
              <Checkbox />
              <Text style={{ fontSize: 9 }}>{label}</Text>
            </View>
          ))}
        </View>
      </View>

      <NotesSection />
      <FooterSection />
      <FooterPageNumber />
    </Page>
  );
}
