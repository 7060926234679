import React from "react";
import { TabsList, TabsTrigger, TabsContent } from "../../../components/ui/tabs";
import { WorkRequestAnalytics } from "./WorkRequestAnalytics/WorkRequestAnalytics";
import { WorkOrderAnalytics } from "./WorkOrderAnalytics/WorkOrderAnalytics";
import { PMAnalytic } from "./PMAnalytics/PMAnalytic";
import JkrKpiReport from "./JkrKpiReport";
import LegalComplianceAnalytics from "./LegalComplianceAnalytics/LegalComplianceAnalytics";

interface KDNPDashboardProps {
  currentTabIdx: string;
}

const KDNPDashboard: React.FC<KDNPDashboardProps> = ({ currentTabIdx }) => {
  return (
    <>
      <TabsList>
        <TabsTrigger value="0">
          <p>Work Request</p>
        </TabsTrigger>
        <TabsTrigger value="1">
          <p>Work Order</p>
        </TabsTrigger>
        <TabsTrigger value="2">
          <p>Preventive Maintenance</p>
        </TabsTrigger>
        <TabsTrigger value="4">
          <p>Legal Compliance</p>
        </TabsTrigger>
        {/* <TabsTrigger value="3">
          <p>JKR KPI</p>
        </TabsTrigger> */}
      </TabsList>
      <TabsContent value="0">
        <WorkRequestAnalytics />
      </TabsContent>
      <TabsContent value="1">
        <WorkOrderAnalytics />
      </TabsContent>
      <TabsContent value="2">
        <PMAnalytic />
      </TabsContent>
      <TabsContent value="4">
        <LegalComplianceAnalytics />
      </TabsContent>
      {/* <TabsContent value="3">
        <JkrKpiReport />
      </TabsContent> */}
    </>
  );
};

export default KDNPDashboard;
