import { Camera, Hash, Text } from "lucide-react";
import { useParams } from "react-router-dom";
import { useProjectControllerFindOneProject } from "../../../../../../../api/projects/projects";
import AppItemInfo from "../../../../../../../components/AppItemInfo";
import BimToggleSwitch from "../../../../../../../components/drawers/project/components/BimToggleSwitch";
import CustomWorkPermitToggleSwitch from "../../../../../../../components/drawers/project/components/CustomWorkPermitToggleSwitch";
import EscalationToggleSwitch from "../../../../../../../components/drawers/project/components/EscalationToggleSwitch";
import ExtraFieldPricingTabSwitch from "../../../../../../../components/drawers/project/components/ExtraFieldPricingTabSwitch";
import ManHourSwitch from "../../../../../../../components/drawers/project/components/ManHourSwitch";
import ModuleAccessSwitch from "../../../../../../../components/drawers/project/components/ModuleAccessSwitch";
import PdfReportToggleSwitch from "../../../../../../../components/drawers/project/components/PdfReportToggleSwitch";
import PrivateAttachmentToggleSwitch from "../../../../../../../components/drawers/project/components/PrivateAttachmentToggleSwitch";
import SchedulerToggleSwitch from "../../../../../../../components/drawers/project/components/SchedulerToggleSwitch";
import SerializedInventoryToggleSwitch from "../../../../../../../components/drawers/project/components/SerializedInventoryToggleSwitch";
import SignatorySwitch from "../../../../../../../components/drawers/project/components/SignatorySwitch";
import SubEquipmentToggleSwitch from "../../../../../../../components/drawers/project/components/SubEquipmentToggleSwitch";
import WoProfitLossSwitch from "../../../../../../../components/drawers/project/components/WoProfitLossSwitch";
import WRReviewSwitch from "../../../../../../../components/drawers/project/components/WRReviewSwitch";
import { Card, CardContent } from "../../../../../../../components/ui/card";
import PlanSummaryCard from "./components/PlanSummaryCard/PlanSummaryCard";
import UserQuotaSummaryCard from "./components/UserQuotaSummaryCard/UserQuotaSummaryCard";
import EquipmentLCCAToggleSwitch from "../../../../../../../components/drawers/project/components/EquipmentLCCAToggleSwitch";
import WorkPriorityToggleSwitch from "../../../../../../../components/drawers/project/components/WorkPriorityToggleSwitch";
import WorkTradeToggleSwitch from "../../../../../../../components/drawers/project/components/WorkTradeToggleSwitch";
import SelfAssignToggleSwitch from "../../../../../../../components/drawers/project/components/SelfAssignToggleSwitch"; // Import the new toggle switch
import InvestigationToggleSwitch from "../../../../../../../components/drawers/project/components/InvestigationToggleSwitch";

export default function SummaryTab() {
  const { projectId } = useParams();

  const { data: project } = useProjectControllerFindOneProject(
    projectId as string,
    {
      query: {
        select: (res) => res.data.data,
      },
    }
  );

  return (
    <div className="flex-grow flex flex-col items-start gap-6">
      <h2 className="font-bold text-2xl">Plans</h2>
      <div className="grid grid-cols-2 gap-2 max-w-screen-lg w-full">
        <PlanSummaryCard />
        <UserQuotaSummaryCard />
      </div>
      <h2 className="font-bold text-2xl">Modules</h2>
      <div className="grid grid-cols-2 gap-4 max-w-screen-lg">
        <Card>
          <CardContent className="p-4 flex gap-4 items-center justify-between">
            <div>
              <p className="text-lg font-medium">Scheduler Module</p>
              <p className="text-sm leading-relaxed text-slate-500">
                Plan work orders with calendar view, PM with a calendar view
              </p>
            </div>
            <SchedulerToggleSwitch />
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4 flex gap-4 items-center justify-between">
            <div>
              <p className="text-lg font-medium">BIM Module</p>
              <p className="text-sm leading-relaxed text-slate-500">
                Building Information Modelling integration. 3D Building
                navigation and equipment linking
              </p>
            </div>
            <BimToggleSwitch />
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4 flex gap-4 items-center justify-between">
            <div>
              <p className="text-lg font-medium">Serialized Inventory</p>
              <p className="text-sm leading-relaxed text-slate-500">
                Allow users to serialize the inventory. Each inventory will have
                their own serial number.
              </p>
            </div>
            <SerializedInventoryToggleSwitch />
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4 flex gap-4 items-center justify-between">
            <div>
              <p className="text-lg font-medium">Custom Work Permit</p>
              <p className="text-sm leading-relaxed text-slate-500">
                Allow users to customize multiple templates of their work permit
              </p>
            </div>
            <CustomWorkPermitToggleSwitch />
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4 flex gap-4 items-center justify-between">
            <div>
              <p className="text-lg font-medium">PDF Report</p>
              <p className="text-sm leading-relaxed text-slate-500">
                Allows user to download summary in pdf format to be shared
                externally
              </p>
            </div>
            <PdfReportToggleSwitch />
          </CardContent>
        </Card>
        {/* <Card>
          <CardContent className="p-4 flex gap-4 items-center justify-between">
            <div>
              <p className="text-lg font-medium">Save User Info - Work Request</p>
              <p className="text-sm leading-relaxed text-slate-500">
                Allows user to save their previously keyed in work request email, and contact number.
              </p>
            </div>
            <WorkRequestSaveInfoToggleSwitch />
          </CardContent>
        </Card> */}
        <Card>
          <CardContent className="p-4 flex gap-4 items-center justify-between">
            <div>
              <p className="text-lg font-medium">Work Request Review</p>
              <p className="text-sm leading-relaxed text-slate-500">
                Allow user who raise work request to leave a review
              </p>
            </div>
            <WRReviewSwitch />
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4 flex gap-4 items-center justify-between">
            <div>
              <p className="text-lg font-medium">Man Hour Feature</p>
              <p className="text-sm leading-relaxed text-slate-500">
                Add manhours in work order, able to calculate normal rate, and
                overtime rate accordingly
              </p>
            </div>
            <ManHourSwitch />
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4 flex gap-4 items-center justify-between">
            <div>
              <p className="text-lg font-medium">Work Order Signatory</p>
              <p className="text-sm leading-relaxed text-slate-500">
                Add signatory in work order, allow users to provide sign off in
                comment section
              </p>
            </div>
            <SignatorySwitch />
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4 flex gap-4 items-center justify-between">
            <div>
              <p className="text-lg font-medium">Profit and Loss</p>
              <p className="text-sm leading-relaxed text-slate-500">
                Calculates for user profit and loss of work orders
              </p>
            </div>
            <WoProfitLossSwitch />
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4 flex gap-4 items-center justify-between">
            <div>
              <p className="text-lg font-medium">Module Access</p>
              <p className="text-sm leading-relaxed text-slate-500">
                Ability for company admins to select which user has access to
                which modules
              </p>
            </div>
            <ModuleAccessSwitch />
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4 flex gap-4 items-center justify-between">
            <div>
              <p className="text-lg font-medium">Private Attachment</p>
              <p className="text-sm leading-relaxed text-slate-500">
                Ability for users to mark as private attachment and only
                accessible by selected users
              </p>
            </div>
            <PrivateAttachmentToggleSwitch />
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4 flex gap-4 items-center justify-between">
            <div>
              <p className="text-lg font-medium">Escalation</p>
              <p className="text-sm leading-relaxed text-slate-500">
                Allow users to set condition on how many days aging will trigger
                an email notification to selected users for work request, work
                orders and PM
              </p>
            </div>
            <EscalationToggleSwitch />
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4 flex gap-4 items-center justify-between">
            <div>
              <p className="text-lg font-medium">Extra Pricing Tab</p>
              <p className="text-sm leading-relaxed text-slate-500">
                Exclusively for Oasis Square only
              </p>
            </div>
            <ExtraFieldPricingTabSwitch />
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4 flex gap-4 items-center justify-between">
            <div>
              <p className="text-lg font-medium">Sub equipment</p>
              <p className="text-sm leading-relaxed text-slate-500">
                Allow sub equipments to be defined under any equipment
              </p>
            </div>
            <SubEquipmentToggleSwitch />
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4 flex gap-4 items-center justify-between">
            <div>
              <p className="text-lg font-medium">Equipment LCCA</p>
              <p className="text-sm leading-relaxed text-slate-500">
                Enable Life Cycle Cost Analysis for equipment
              </p>
            </div>
            <EquipmentLCCAToggleSwitch />
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4 flex gap-4 items-center justify-between">
            <div>
              <p className="text-lg font-medium">Work Order Priority</p>
              <p className="text-sm leading-relaxed text-slate-500">
                Enable Work Order Priority feature
              </p>
            </div>
            <WorkPriorityToggleSwitch />
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4 flex gap-4 items-center justify-between">
            <div>
              <p className="text-lg font-medium">Work Order Trade</p>
              <p className="text-sm leading-relaxed text-slate-500">
                Enable Work Order Trade feature
              </p>
            </div>
            <WorkTradeToggleSwitch />
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4 flex gap-4 items-center justify-between">
            <div>
              <p className="text-lg font-medium">Self-Assign Work Order</p>
              <p className="text-sm leading-relaxed text-slate-500">
                Allow users to self-assign work orders to themselves
              </p>
            </div>
            <SelfAssignToggleSwitch />
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4 flex gap-4 items-center justify-between">
            <div>
              <p className="text-lg font-medium">Investigation</p>
              <p className="text-sm leading-relaxed text-slate-500">
                Enable root cause and corrective action fields for work orders
              </p>
            </div>
            <InvestigationToggleSwitch />
          </CardContent>
        </Card>
      </div>
      <h3 className="text-2xl font-bold">General Information</h3>
      <div className="grid grid-cols-2 gap-4 max-w-screen-lg w-full">
        <AppItemInfo
          label="Name"
          content={<p>{project?.name ?? "-"}</p>}
          icon={<Text />}
        />
        <AppItemInfo
          label="Code"
          content={<p>{project?.code ?? "-"}</p>}
          icon={<Text />}
        />
        <AppItemInfo
          label="Environment Type"
          content={<p>{project?.environmentType ?? "-"}</p>}
          icon={<Hash />}
        />
        <div className="col-span-2">
          <AppItemInfo
            label="Photo"
            content={
              <div>
                {project?.attachments?.map((att, idx) => (
                  <img
                    className="w-[300px] h-[300px] rounded-xl object-cover shadow-xl"
                    src={att.url}
                    key={idx}
                  />
                ))}
              </div>
            }
            icon={<Camera />}
          />
        </div>
      </div>
    </div>
  );
}
