import AppButton from "@/components/AppButton";
import {
  DRAWER_CREATE_STATE,
  WORK_ORDER_TRADE_FORM_DRAWER
} from "@/components/drawers/AppDrawer";
import { useSearchParams } from "react-router-dom";
import AppTitle from "../../../HomeScreen/components/AppTitle";
import WorkOrderTradeTable from "./WorkOrderTradeTable";

export default function WorkOrderTrade() {
  const [, setSearchParams] = useSearchParams();

  const handleCreate = () => {
    setSearchParams({
      drawerType: WORK_ORDER_TRADE_FORM_DRAWER,
      drawerState: DRAWER_CREATE_STATE,
    });
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        <AppTitle title="Work Order Trades" className="mb-0" />
        <AppButton label="Create New Trade" onClick={handleCreate} />
      </div>
      <p className="text-sm text-slate-600">
        Manage work order trade options here
      </p>
      <WorkOrderTradeTable />
    </div>
  );
}
