import { Archive, Info, Users2 } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { GetOneWorkOrderResponseDto } from "../../../../../api/model";
import InventoryPolicyGuard from "../../../../../guards/InventoryPolicyGuard";
import WorkOrderPricingPolicyGuard from "../../../../../guards/WorkOrderPricingPolicyGuard";
import { Vendor } from "../../../../../redux/slices/OpenApi/cerevApi";
import AppItemInfo from "../../../../AppItemInfo";
import { Separator } from "../../../../ui/separator";
import AddInventoryBtnDialog from "./components/AddInventoryBtnDialog/AddInventoryBtnDialog";
import InventoryUsageLogs from "./components/InventoryUsageLogs";
import PricingSection from "./components/PricingSection";
import UpdateAssignmentBtnDialog from "./components/UpdateAssignmentBtnDialog/UpdateAssignmentBtnDialog";
import WorkRequestUpdate from "./components/WorkRequestUpdate/WorkRequestUpdate";
import VerificationSection from "./components/VerificationSection";
import InvestigationSection from "./components/InvestigationSection";
import { useInvestigationEnabled } from "../../../../../hooks/useFeatures";

export default function OthersTab({
  workOrder,
}: {
  workOrder?: GetOneWorkOrderResponseDto;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const isInvestigationEnabled = useInvestigationEnabled();

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-3">
        <p className="text-xl font-bold">Assignments</p>
        <div className="grid grid-cols-2 gap-4">
          <AppItemInfo
            label="Assigned Vendors"
            icon={<Users2 className="text-primary-900" />}
            content={
              workOrder?.assignedUsers?.length ? (
                <div className="flex flex-col gap-2">
                  {workOrder.assignedUsers.reduce((pre, curr) => {
                    // If does not exist in pre, add it
                    if (
                      !pre.find((v) => v.id === curr?.vendor?.id) &&
                      curr.vendor
                    ) {
                      pre.push(curr.vendor);
                    }
                    return pre;
                  }, [] as Vendor[]).length === 0 && <p>-</p>}
                  {workOrder.assignedUsers
                    .reduce((pre, curr) => {
                      // If does not exist in pre, add it
                      if (
                        !pre.find((v) => v.id === curr?.vendor?.id) &&
                        curr.vendor
                      ) {
                        pre.push(curr.vendor);
                      }
                      return pre;
                    }, [] as Vendor[])
                    .map((ven) => (
                      <div
                        className="rounded-xl bg-neutral-100 py-1 px-4"
                        key={ven.id}
                      >
                        <p>{ven?.name ?? "-"}</p>
                      </div>
                    ))}
                  {workOrder.assignedUsers.reduce((pre, curr) => {
                    // If does not exist in pre, add it
                    if (
                      !pre.find((v) => v.id === curr?.vendor?.id) &&
                      curr.vendor
                    ) {
                      pre.push(curr.vendor);
                    }
                    return pre;
                  }, [] as Vendor[]).length === 0 && <p>-</p>}
                </div>
              ) : (
                <p>-</p>
              )
            }
          />
          <AppItemInfo
            label="Assigned Users"
            icon={<Users2 className="text-primary-900" />}
            content={
              workOrder?.assignedUsers?.length ? (
                <div className="flex flex-col gap-2">
                  {workOrder.assignedUsers.length === 0 && <p>-</p>}
                  {workOrder.assignedUsers.map((usr) => (
                    <div
                      className="rounded-xl bg-neutral-100 py-1 px-4"
                      key={usr.id}
                    >
                      <p>{usr.name ?? "-"}</p>
                    </div>
                  ))}
                </div>
              ) : (
                <p>-</p>
              )
            }
          />
          <div className="flex">
            <UpdateAssignmentBtnDialog
              initialSelection={workOrder?.assignedUsers ?? []}
              workOrder={workOrder}
            />
          </div>
        </div>
      </div>
      <Separator />
      <div className="flex flex-col gap-3">
        <p className="text-xl font-bold">Inventory</p>
        <AppItemInfo
          label="Inventory Used"
          icon={<Archive className="text-primary-900" />}
          content={
            <div className="flex flex-col gap-2">
              {workOrder?.stockLedgers.length === 0 && <p>-</p>}
              <InventoryUsageLogs workOrder={workOrder} />
              <InventoryPolicyGuard>
                <div className="flex">
                  <AddInventoryBtnDialog workOrder={workOrder} />
                </div>
              </InventoryPolicyGuard>
            </div>
          }
        />
      </div>
      <Separator />
      <div className="flex flex-col gap-3">
        <p className="text-xl font-bold">Work Request</p>
        <AppItemInfo
          label="Work Requests Linked"
          icon={<Info className="text-primary-900" />}
          content={<WorkRequestUpdate />}
        />
      </div>
      <Separator />
      <VerificationSection />
      <Separator />
      {isInvestigationEnabled && <InvestigationSection />}
      {isInvestigationEnabled && <Separator />}
      <WorkOrderPricingPolicyGuard>
        <PricingSection />
      </WorkOrderPricingPolicyGuard>
    </div>
  );
}
