import * as React from "react";
import { Text, View } from "@react-pdf/renderer";
import { useJkrPataWorkOrder } from "..";

export function NotesSection() {
  const workOrder = useJkrPataWorkOrder();
  
  return (
    <View style={{ marginTop: 12 }} wrap={false}>
      <Text style={{ fontSize: 9, marginBottom: 6 }}>
        CATATAN ADUAN (Nyatakan dengan jelas kerosakan yang berlaku)
      </Text>
      <View
        style={{
          border: "1px solid black",
          height: 100,
          padding: 6,
        }}
      >
        <Text style={{ fontSize: 9 }}>
          {workOrder.workRequests?.[0]?.description ?? ""}
        </Text>
      </View>
    </View>
  );
}
