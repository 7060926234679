import { useQueryClient } from "@tanstack/react-query";
import { Pencil } from "lucide-react";
import { useState } from "react";
import { useAuthControllerGetCurrentUser } from "../../../../../../../api/auth/auth";
import {
  AssignedUserWithVendor,
  GetOneWorkOrderResponseDto,
} from "../../../../../../../api/model";
import { useUserControllerGetUsers } from "../../../../../../../api/users/users";
import useIsCloseWorkOrder from "../../../../../../../hooks/useIsCloseWorkOrder";
import { useAppSelector } from "../../../../../../../redux/store";
import AppButton from "../../../../../../AppButton";
import DialogContentWithSearchTable from "../../../../../../dialogs/AppSelectWithDialog/components/DialogContentWithSearchTable";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../../../ui/dialog";
import { updateAssignmentColumns } from "./updateAssignmentColumns";
import { useHasWorkOrderEditAfterClosePolicy } from "../../../../../../../hooks/usePolicies/usePolicies";
import { trpc } from "../../../../../../../lib/trpc"; // Import trpc

interface UpdateAssignmentBtnDialogProps {
  initialSelection: AssignedUserWithVendor[];
  workOrder?: GetOneWorkOrderResponseDto;
}

export default function UpdateAssignmentBtnDialog({
  initialSelection,
  workOrder,
}: UpdateAssignmentBtnDialogProps) {
  const qc = useQueryClient();
  const [open, setOpen] = useState(false);
  const isClose = useIsCloseWorkOrder({ workOrder });
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const { data: user } = useAuthControllerGetCurrentUser();
  const hasEditAfterClosePolicy = useHasWorkOrderEditAfterClosePolicy();

  const { data: userList } = useUserControllerGetUsers(
    {
      projectId: activeProj?.id ?? 0,
      userId: user?.data?.id ?? 0,
      companyId: activeComp?.id ?? 0,
    },
    {
      query: {
        enabled: !!activeProj && !!user && !!activeComp,
      },
    }
  );

  const { mutate: updateWorkOrder, isPending: updateWorkOrderIsPending } =
    trpc.workOrders.updateWorkOrder.useMutation({ // Use trpc mutation
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (query) => {
            return (
              (query.queryKey[0] as any).includes("comment") ||
              (query.queryKey[0] as any).includes("defect")
            );
          },
        });
      },
    });

  const handleUpdate = (
    users: AssignedUserWithVendor | AssignedUserWithVendor[]
  ) => {
    if (!workOrder) return;
    if (Array.isArray(users)) {
      updateWorkOrder({
        defectId: workOrder.id ?? 0,
        updatedById: user?.data?.id ?? 0,
        assignedUsers: users.map((u) => u?.id ?? 0),
      });
    } else {
      updateWorkOrder({
        defectId: workOrder.id ?? 0,
        updatedById: user?.data?.id ?? 0,
        assignedUsers: [users?.id ?? 0],
      });
    }
  };

  if (isClose && !hasEditAfterClosePolicy) return <></>;

  return (
    <div className="flex flex-col gap-2">
      <AppButton
        data-testid="update-assignment-btn"
        isLoading={updateWorkOrderIsPending}
        icon={<Pencil />}
        label="Update"
        onClick={() => {
          setOpen(true);
        }}
      />
      <Dialog open={open} onOpenChange={(v) => setOpen(v)}>
        <DialogContent className="w-full max-w-4xl overflow-y-scroll max-h-screen">
          <DialogHeader>
            <DialogTitle>Update Assignment</DialogTitle>
          </DialogHeader>
          <DialogContentWithSearchTable
            items={userList?.data ?? []}
            value={initialSelection}
            columns={updateAssignmentColumns}
            setRowSelection={handleUpdate}
            onSelected={(selected) => {
              handleUpdate(selected);
              setOpen(false);
            }}
            closeDialog={() => {
              setOpen(false);
            }}
            multiple
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
