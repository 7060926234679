import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { trpc } from "@/lib/trpc";
import { useAppSelector } from "@/redux/store";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export default function WorkPrioritySelect() {
  const [searchParams] = useSearchParams();
  const workOrderId = searchParams.get("workOrderId");
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [currentWorkPriorityId, setCurrentWorkPriorityId] = useState<string>("");
  const utils = trpc.useUtils();

  const { data: workOrder, isLoading: isWorkOrderLoading } = trpc.workOrders.getOneWorkOrder.useQuery(
    { workOrderId: Number(workOrderId) },
    { enabled: !!workOrderId }
  );

  const { data: workPriorities, isLoading: isWorkPrioritiesLoading } = trpc.workOrders.getWorkOrderPriorities.useQuery(
    { projectId: Number(activeProj?.id) },
    { enabled: !!activeProj }
  );

  const updateWorkPriorityMutation = trpc.workOrders.updateWoWorkPriority.useMutation({
    onSuccess: () => {
      utils.workOrders.getOneWorkOrder.invalidate({ workOrderId: Number(workOrderId) });
    },
  });

  useEffect(() => {
    if (workOrder && workOrder.workPriority) {
      setCurrentWorkPriorityId(workOrder.workPriority.id.toString());
    }
  }, [workOrder]);

  const handleWorkPriorityChange = (value: string) => {
    setCurrentWorkPriorityId(value);
    updateWorkPriorityMutation.mutate({
      workOrderId: Number(workOrderId),
      workPriorityId: Number(value),
    });
  };

  if (isWorkOrderLoading || isWorkPrioritiesLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-2">
      <Select onValueChange={handleWorkPriorityChange} value={currentWorkPriorityId}>
        <SelectTrigger className="w-[200px] h-8 mt-1">
          <SelectValue placeholder="Select Work Priority" />
        </SelectTrigger>
        <SelectContent>
          {workPriorities?.map((priority) => (
            <SelectItem key={priority.id} value={priority.id.toString()}>
              {priority.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}

