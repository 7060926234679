import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import AppButton from "@/components/AppButton";
import AppTextField from "@/components/AppTextField";
import { DRAWER_CREATE_STATE } from "./AppDrawer";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CreateWorkOrderPriorityInput,
  UpdateWorkOrderPriorityInput,
  createWorkOrderPrioritySchema,
  updateWorkOrderPrioritySchema,
} from "@cerev-cmms/zod-types";
import { trpc } from "../../lib/trpc";
import { useAppSelector } from "../../redux/store";

export default function WorkOrderPriorityFormDrawer() {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [searchParams, setSearchParams] = useSearchParams();
  const drawerState = searchParams.get("drawerState");
  const id = searchParams.get("workOrderPriorityId");
  const util = trpc.useUtils();

  const { data: workOrderPriority } =
    trpc.workOrders.getOneWorkOrderPriority.useQuery(
      { id: Number(id) },
      { enabled: !!id }
    );

  useEffect(() => {
    if (workOrderPriority) {
      methods.reset(workOrderPriority);
    }
  }, [workOrderPriority]);

  const { mutate: createMutation, isPending: isCreateLoading } =
    trpc.workOrders.createWorkOrderPriority.useMutation({
      onSuccess: () => {
        util.workOrders.getWorkOrderPriorities.invalidate();
        util.workOrders.getOneWorkOrderPriority.invalidate();
        setSearchParams((p) => {
          p.delete("drawerType");
          p.delete("drawerState");
          p.delete("workOrderPriorityId");
          return p;
        });
      },
    });
  const { mutate: updateMutation, isPending: isUpdateLoading } =
    trpc.workOrders.updateWorkOrderPriority.useMutation({
      onSuccess: () => {
        util.workOrders.getWorkOrderPriorities.invalidate();
        util.workOrders.getOneWorkOrderPriority.invalidate();
        setSearchParams((p) => {
          p.delete("drawerType");
          p.delete("drawerState");
          p.delete("workOrderPriorityId");
          return p;
        });
      },
    });

  const methods = useForm<
    CreateWorkOrderPriorityInput | UpdateWorkOrderPriorityInput
  >({
    resolver: zodResolver(
      drawerState === DRAWER_CREATE_STATE
        ? createWorkOrderPrioritySchema
        : updateWorkOrderPrioritySchema
    ),
    defaultValues: {
      name: workOrderPriority?.name || "",
      order: workOrderPriority?.order || 0,
      projectId: Number(activeProj?.id) ?? -1,
    },
  });

  const onSubmit = (
    data: CreateWorkOrderPriorityInput | UpdateWorkOrderPriorityInput
  ) => {
    if (drawerState === DRAWER_CREATE_STATE) {
      createMutation(data as CreateWorkOrderPriorityInput);
    } else {
      updateMutation({
        ...data,
        id: Number(id),
      } as UpdateWorkOrderPriorityInput);
    }

    // Close drawer or show success message
  };

  return (
    <FormProvider {...methods}>
      <div className="space-y-4">
        <h2 className="text-2xl font-bold">
          {drawerState === DRAWER_CREATE_STATE ? "Create" : "Update"} Work Order
          Priority
        </h2>
        <AppTextField
          name="name"
          label="Priority Name"
          placeholder="Enter priority name"
        />
        <AppButton
          label={drawerState === DRAWER_CREATE_STATE ? "Create" : "Update"}
          onClick={methods.handleSubmit(onSubmit)}
          isLoading={isCreateLoading || isUpdateLoading}
        />
      </div>
    </FormProvider>
  );
}
