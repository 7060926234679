import { trpc } from "../../../../lib/trpc";
import { useAppSelector } from "../../../../redux/store";
import { WorkOrderStatusCountCard } from "./WorkOrderStatusCountCard";

export function WorkOrderStatusCountSection() {
  const project = useAppSelector((state) => state.root.activeProject);
  const { data } = trpc.workOrders.getWorkOrderStatus.useQuery({
    projectId: project?.id ?? 0,
  });

  return (
    <>
      {data?.map((statusCount) => (
        <WorkOrderStatusCountCard statusCount={statusCount} key={statusCount.name} />
      ))}
    </>
  );
}
