import { trpc } from "@/lib/trpc";
import { useAppSelector } from "@/redux/store";
import { RouterOutputs } from "@cerev-cmms/trpc/src/lib/trpc/root";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { DataTable } from "../../../../components/react-table/DataTable";
import { workPriorityColumns } from "./workPriorityColumns";

type WorkOrderPriority =
  RouterOutputs["workOrders"]["getWorkOrderPriorities"][number];

export default function WorkOrderPriorityTable() {
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { data: workOrderPriorities } =
    trpc.workOrders.getWorkOrderPriorities.useQuery(
      {
        projectId: Number(activeProj?.id) ?? -1,
      },
      {
        enabled: !!activeProj,
      }
    );

  const table = useReactTable({
    data: workOrderPriorities || [],
    columns: workPriorityColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return <DataTable table={table} totalCount={table.getTotalSize()} />;
}
