import DeleteWorkOrderBtn from "@/components/drawers/workOrders/tabs/WorkOrderInfoTab/components/DeleteWorkOrderBtn";
import RoleGuard from "@/guards/RoleGuard";
import {
  AlignLeft,
  Bookmark,
  CalendarCheck2,
  CalendarClock,
  Check,
  Computer,
  FileDigit,
  FileText,
  Flag,
  MapPin,
  Star,
  User2,
  Video,
  Workflow,
  Wrench,
} from "lucide-react";
import { GetOneWorkOrderResponseDto } from "../../../../../api/model";
import useDate from "../../../../../hooks/useDate";
import useIsCloseWorkOrder from "../../../../../hooks/useIsCloseWorkOrder";
import AppFullLocation from "../../../../AppFullLocation";
import AppItemInfo from "../../../../AppItemInfo";
import AppMultiImage from "../../../../AppMultiImage";
import AppWorkOrderStatusChip from "../../../../AppWorkOrderStatusChip";
import AppWorkOrderStatusUpdateMenu from "../../../../AppWorkOrderStatusUpdateMenu";
import { AppRatings } from "../../../../rating/AppRatings";
import AddDocBtn from "./components/AddDocBtn";
import ExportPdfBtn from "./components/ExportPdfBtn";
import WorkOrderDocVidItem from "./components/WorkOrderDocVidItem";
import AppButton from "../../../../AppButton";
import { useAppSelector } from "../../../../../redux/store";
import { Link, useSearchParams } from "react-router-dom";
import WorkTradeSelect from "./components/WorkTradeSelect";
import WorkPrioritySelect from "./components/WorkPrioritySelect";
import {
  useWoPriorityEnabled,
  useWoTradeEnabled,
} from "../../../../../hooks/useFeatures";
import { is } from "date-fns/locale";
import { DRAWER_VIEW_STATE } from "../../../AppDrawer";
import { ASSET_DETAIL_DRAWER } from "../../../AppDrawer";

export default function WorkOrderInfoTab({
  workOrder,
}: {
  workOrder?: GetOneWorkOrderResponseDto;
}) {
  const [, setSearchParams] = useSearchParams();

  const { formatDateToLocal, diffInDays } = useDate();
  const isClose = useIsCloseWorkOrder({ workOrder });
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const isWoPriorityEnabled = useWoPriorityEnabled({
    projectId: activeProj?.id?.toString(),
  });
  const isWoTradeEnabled = useWoTradeEnabled({
    projectId: activeProj?.id?.toString(),
  });

  return (
    <div className="grid grid-cols-2 gap-6">
      {workOrder?.photos?.length ? (
        <div className="h-64 col-span-2">
          <AppMultiImage
            attachments={workOrder?.photos}
            className="h-64 w-[500px] border-solid border-neutral-200"
          />
        </div>
      ) : (
        <></>
      )}

      <div className="flex gap-4 items-center col-span-2">
        <AppWorkOrderStatusChip workOrderStatus={workOrder?.defectStatus} />
        <AppWorkOrderStatusUpdateMenu workOrder={workOrder} />
      </div>
      <AppItemInfo
        label="Subject"
        icon={<Bookmark className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workOrder?.subject ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Description"
        icon={<AlignLeft className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workOrder?.description ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Ref No"
        icon={<FileDigit className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workOrder?.refNo ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Completion Days"
        icon={<CalendarClock className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workOrder?.completedOn
              ? diffInDays({
                  startDateISO: workOrder?.createdOn,
                  endDateISO: workOrder?.completedOn,
                })
              : "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Created"
        icon={<CalendarCheck2 className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workOrder?.createdOn
              ? formatDateToLocal({ dateStr: workOrder?.createdOn })
              : "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Closed On"
        icon={<Check className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workOrder?.completedOn
              ? formatDateToLocal({ dateStr: workOrder?.completedOn })
              : "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Equipment"
        icon={<Computer className="text-primary-900" />}
        content={
          <div
            className="flex flex-col decoration-primary-900 text-primary-900 underline hover:cursor-pointer"
            onClick={() => {
              setSearchParams((p) => {
                p.set("drawerType", ASSET_DETAIL_DRAWER);
                p.set("drawerState", DRAWER_VIEW_STATE);
                p.set("assetId", workOrder?.asset?.id?.toString() ?? "");
                p.set("assetDetailTabIdx", "0");
                return p;
              });
            }}
          >
            <p>{workOrder?.asset?.name}</p>
          </div>
        }
      />
      <AppItemInfo
        label="Equipment Code"
        icon={<Computer className="text-primary-900" />}
        content={
          <div className="flex flex-col">
            <p>{workOrder?.asset?.code}</p>
          </div>
        }
      />
      <AppItemInfo
        label="Raised By"
        icon={<User2 className="text-primary-900" />}
        content={
          <p className="font-sans text-neutral-900">
            {workOrder?.createdBy?.name ?? "-"}
          </p>
        }
      />
      <AppItemInfo
        label="Location"
        icon={<MapPin className="text-primary-900" />}
        content={
          <AppFullLocation locationId={workOrder?.locationId?.toString()} />
        }
      />
      <AppItemInfo
        label="Work Order Type"
        icon={<Workflow className="text-primary-900" />}
        content={<p>{workOrder?.defectType?.name ?? "-"}</p>}
      />
      {isWoPriorityEnabled && (
        <AppItemInfo
          label="Work Priority"
          icon={<Flag className="text-primary-900" />}
          content={<WorkPrioritySelect />}
        />
      )}
      {isWoTradeEnabled && (
        <AppItemInfo
          label="Work Trade"
          icon={<Wrench className="text-primary-900" />}
          content={<WorkTradeSelect />}
        />
      )}
      <div className="col-span-2">
        <AppItemInfo
          label="Video or Documents"
          icon={<Video className="text-primary-900" />}
          content={
            <div className="flex flex-col gap-4">
              {workOrder?.docs?.length ? (
                workOrder?.docs.map((p) => (
                  <WorkOrderDocVidItem att={p} key={p.id} />
                ))
              ) : (
                <p className="font-sans text-neutral-900">-</p>
              )}
              <div>
                <AddDocBtn />
              </div>
            </div>
          }
        />
      </div>
      <div className="flex gap-4 col-span-2">
        <ExportPdfBtn workOrder={workOrder} />
        {activeProj?.code === "KDNP" && (
          <Link target="_blank" to={`/customPdf/jkr/${workOrder?.id}`}>
            <AppButton
              variant="outline"
              icon={<FileText />}
              label="Export JKR Work Order"
            />
          </Link>
        )}
        <RoleGuard roleName="manager">
          <DeleteWorkOrderBtn />
        </RoleGuard>
      </div>
    </div>
  );
}
