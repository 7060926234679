import { useSearchParams } from "react-router-dom";
import {
  ASSET_DETAIL_DRAWER,
  ASSET_FORM_DRAWER,
  DRAWER_CREATE_STATE,
  DRAWER_VIEW_STATE,
} from "../../../components/drawers/AppDrawer";
import { useAssetControllerGetAssetsQuery } from "../../../redux/slices/OpenApi/cerevApi";
import { useAppSelector } from "../../../redux/store";
import DrawerFormSkeleton from "../../../components/skeletons/DrawerFormSkeleton";
import AppButton from "../../../components/AppButton";
import AppTable from "../../../components/table/AppTable";
import { Plus } from "lucide-react";
import { TableCell, TableHead, TableRow } from "@mui/material";
import { useAssetControllerGetAssets } from "../../../api/assets/assets";

interface LocationAssetListSectionProps {
  locationId?: number;
}

export default function LocationAssetListSection({
  locationId,
}: LocationAssetListSectionProps) {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [, setSearchParams] = useSearchParams();

  const { data: assets, isLoading } = useAssetControllerGetAssets(
    {
      projectId: activeProj?.id?.toString() ?? "",
      locationId: locationId?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj && !!locationId,
        select: (res) => res.data,
      },
    }
  );

  if (!locationId) return <></>;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <p className="font-sans text-2xl font-bold">Equipments</p>
        <AppButton
          label="+ Create"
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", ASSET_FORM_DRAWER);
              p.set("drawerState", DRAWER_CREATE_STATE);
              p.set("locationId", locationId.toString());
              return p;
            });
          }}
        />
      </div>
      <AppTable
        isLoading={isLoading}
        colSpan={3}
        isEmpty={assets?.length === 0}
        headerTableRow={
          <TableHead>
            <TableRow>
              <TableCell className="font-sans text-lg font-bold">
                Equipment Name
              </TableCell>
              <TableCell className="font-sans text-lg font-bold">
                Code
              </TableCell>
              <TableCell className="font-sans text-lg font-bold">
                Equipment Type
              </TableCell>
            </TableRow>
          </TableHead>
        }
        bodyTableRow={assets?.map((asset) => (
          <TableRow key={asset.id}>
            <TableCell>
              <div
                className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
                onClick={() => {
                  setSearchParams((p) => {
                    p.set("drawerType", ASSET_DETAIL_DRAWER);
                    p.set("drawerState", DRAWER_VIEW_STATE);
                    p.set("assetId", asset.id.toString());
                    p.set("assetDetailTabIdx", "0");
                    return p;
                  });
                }}
              >
                {asset.name}
              </div>
            </TableCell>
            <TableCell>{asset.code}</TableCell>
            <TableCell>{asset.assetType?.name}</TableCell>
          </TableRow>
        ))}
      />
    </div>
  );
}
