import { trpc } from "@/lib/trpc";
import { useAppSelector } from "@/redux/store";
import { RouterOutputs } from "@cerev-cmms/trpc/src/lib/trpc/root";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { DataTable } from "../../../../components/react-table/DataTable";
import { workTradeColumns } from "./workTradeColumns";


export default function WorkOrderTradeTable() {
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { data: workTrades } = trpc.workOrders.getWorkTrades.useQuery(
    {
      projectId: Number(activeProj?.id) ?? -1,
    },
    {
      enabled: !!activeProj,
    }
  );

  const table = useReactTable({
    data: workTrades || [],
    columns: workTradeColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return <DataTable table={table} totalCount={table.getTotalSize()} />;
}
