import { z } from "zod";

export const updateUserDataSchema = z.object({
    userId: z.number(),
    name: z.string(),
    email: z.string().email(),
    phoneNo: z.string().optional(),
    position: z.string().optional(),
    projectIds: z.array(z.number()),
    attachments: z.array(z.object({
      id: z.number()
    })),
    accountType: z.enum(["SUPERADMIN", "COMPANY_ADMIN", "NORMAL_USER", "VENDOR"]),
    updatedById: z.number()
  });
  
  export type UpdateUserDataInput = z.infer<typeof updateUserDataSchema>;