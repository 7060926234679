import { z } from "zod";

export const getAnalyticsInput = z.object({
  projectId: z.number(),
});

export type GetAnalyticsInput = z.infer<typeof getAnalyticsInput>;

export const getPMApportionmentInput = z.object({
  projectId: z.number(),
});

export type GetPMApportionmentInput = z.infer<typeof getPMApportionmentInput>;

// Add the new type for getJkrKpiReport
export const getJkrKpiReportInput = z.object({
  projectId: z.number(),
  electricalKeyword: z.string(),
});

export type GetJkrKpiReportInput = z.infer<typeof getJkrKpiReportInput>;

export const getWorkRequestSubmissionTrendInput = z.object({
  projectId: z.number(),
  year: z.number().optional(),
  month: z.number().optional(),
});

export type GetWorkRequestSubmissionTrendInput = z.infer<typeof getWorkRequestSubmissionTrendInput>;

export const getWorkRequestStatusBreakdownInput = z.object({
  projectId: z.number(),
});

export type GetWorkRequestStatusBreakdownInput = z.infer<typeof getWorkRequestStatusBreakdownInput>;

export const getWorkRequestAvgTurnaroundTimeInput = z.object({
  projectId: z.number(),
});

export type GetWorkRequestAvgTurnaroundTimeInput = z.infer<typeof getWorkRequestAvgTurnaroundTimeInput>;

// Add the new type for getAvgWorkRequestRating
export const getAvgWorkRequestRatingInput = z.object({
  projectId: z.number(),
});

export type GetAvgWorkRequestRatingInput = z.infer<typeof getAvgWorkRequestRatingInput>;

export const getWorkOrderTrendInput = z.object({
  projectId: z.number(),
  year: z.number().optional(),
});

export type GetWorkOrderTrendInput = z.infer<typeof getWorkOrderTrendInput>;

export const getWorkOrderTypeApportionmentInput = z.object({
  projectId: z.number(),
});

export type GetWorkOrderTypeApportionmentInput = z.infer<typeof getWorkOrderTypeApportionmentInput>;

export const getWorkOrderClosureRateInput = z.object({
  projectId: z.number(),
});

export type GetWorkOrderClosureRateInput = z.infer<typeof getWorkOrderClosureRateInput>;

export const getPMTrendWithStatusInput = z.object({
  projectId: z.number(),
  year: z.number().optional(),
});

export type GetPMTrendWithStatusInput = z.infer<typeof getPMTrendWithStatusInput>;

export const getPMApportionmentByStatusInput = z.object({
  projectId: z.number(),
});

export type GetPMApportionmentByStatusInput = z.infer<typeof getPMApportionmentByStatusInput>;

export const getAssetPPMCompletionRateSchema = z.object({
  projectId: z.number(),
  limit: z.number().optional(),
});

export type GetAssetPPMCompletionRateInput = z.infer<typeof getAssetPPMCompletionRateSchema>;

export const getWorkRequestKpiAvgTurnaroundTimeInput = z.object({
  projectId: z.number(),
});

export type GetWorkRequestKpiAvgTurnaroundTimeInput = z.infer<typeof getWorkRequestKpiAvgTurnaroundTimeInput>;

export const getWorkRequestDayTrendTurnaroundTimeInput = z.object({
  projectId: z.number(),
  year: z.number().optional(),
  month: z.number().optional(),
});

export type GetWorkRequestDayTrendTurnaroundTimeInput = z.infer<typeof getWorkRequestDayTrendTurnaroundTimeInput>;

export const getExpiredContractPieChartInput = z.object({
  projectId: z.number(),
});

export type GetExpiredContractPieChartInput = z.infer<typeof getExpiredContractPieChartInput>;
