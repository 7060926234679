import { Loader2 } from "lucide-react";
import { useParams } from "react-router-dom";
import { FeatureTypeEnum } from "@cerev-cmms/zod-types";
import { useSchedulerEnabled } from "../../../../hooks/useFeatures";
import { Switch } from "../../../ui/switch";
import { toast } from "sonner";
import { trpc } from "../../../../lib/trpc";

export default function SchedulerToggleSwitch() {
  const util = trpc.useUtils();
  const { projectId } = useParams();
  const scheduleEnabled = useSchedulerEnabled({
    projectId: projectId as string,
  });
  const { mutate: toggleSwitch, isPending } = trpc.featureToggle.toggleFeature.useMutation({
    onSuccess: () => {
      util.featureToggle.getFeatures.invalidate();
      util.featureToggle.isFeatureEnabled.invalidate();
      toast("Successfully updated");
    },
  });

  return (
    <div className="flex items-center gap-4 mt-2">
      <Switch
        className="data-[state=checked]:bg-primary-900"
        checked={scheduleEnabled ?? false}
        onCheckedChange={() => {
          toggleSwitch({
            projectId: projectId as string,
            featureType: FeatureTypeEnum.enum.SCHEDULER,
          });
        }}
      />
      {isPending && (
        <Loader2 className="h-6 w-6 animate-spin text-primary-900" />
      )}
    </div>
  );
}
