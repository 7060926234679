import { AssetPPMCompletionRate } from "./AssetPPMCompletionRate";
import { AssetPPMCompletionRateTable } from "../AssetPPMCompletionRateTable/AssetPPMCompletionRateTable";
import { PMApportionmentStatusPieChart } from "./PMApportionmentStatusPieChart";
import { PmStatusCountSection } from "./PmStatusCountSection";
import { PMTrendAreaChart } from "./PMTrendAreaChart";

export function PMAnalytic() {
  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="col-span-2">
        <PMTrendAreaChart />
      </div>
      <div className="col-span-2">
        <PMApportionmentStatusPieChart />
      </div>
      <div className="col-span-2">
        <AssetPPMCompletionRate />
      </div>
      <PmStatusCountSection />
      <AssetPPMCompletionRateTable />
    </div>
  );
}
