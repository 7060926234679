import React from "react";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { cn } from "../../../lib/utils";

interface KpiCardProps {
  kpiCode: string;
  description: string;
  value: number;
  threshold: number;
}

export const KpiCard: React.FC<KpiCardProps> = ({
  kpiCode,
  description,
  value,
  threshold,
}) => {
  const isAchieved = value >= threshold;
  const hasData = value > 0;

  return (
    <Card className="w-full shadow-none break-inside-avoid">
      <CardContent className="flex flex-col py-4 gap-3 ">
        <div className="flex">
          <div className="flex flex-col">
            <p className="font-bold text-xl">{kpiCode}</p>
            <div className="text-sm text-muted-foreground">{description}</div>
          </div>
        </div>
        <Progress
          value={value}
          className="h-2"
          indicatorClassName={cn(
            "rounded-full",
            hasData
              ? isAchieved
                ? "bg-green-600"
                : "bg-red-600"
              : "bg-slate-400" // Default color when value is 0
          )}
        />
        <div
          className={cn(
            "text-right text-xs",
            hasData
              ? isAchieved
                ? "text-green-600"
                : "text-red-600"
              : "text-muted-foreground" // Default color when value is 0
          )}
        >
          {value.toFixed(2)}%
        </div>
      </CardContent>
    </Card>
  );
};
