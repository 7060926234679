import { GetCommentsResponseDto } from "../../../../../../api/model";
import useDate from "../../../../../../hooks/useDate";
import { useSignatoryEnabled } from "../../../../../../hooks/useFeatures";
import { Avatar, AvatarFallback } from "../../../../../ui/avatar";
import React, { useState } from "react";
import { MoreVertical, Trash } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../../ui/dropdown-menu";
import { Button } from "../../../../../ui/button";
import { DeleteCommentDialog } from "./DeleteCommentDialog";
import { trpc } from "../../../../../../lib/trpc";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";
import useCurrentUser from "../../../../../../hooks/useCurrentUser/useCurrentUser";
import { useAppSelector } from "../../../../../../redux/store";

interface AppCommentProps {
  comment?: GetCommentsResponseDto;
}

export default function AppComment({ comment }: AppCommentProps) {
  const { diffDateTillNow } = useDate();
  const signatoryEnabled = useSignatoryEnabled();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const qc = useQueryClient();
  const { data: user } = useCurrentUser();
  const project = useAppSelector((state) => state.root.activeProject);

  const { data: policyCheck } = trpc.policies.checkOneUserPolicy.useQuery(
    {
      projectId: project?.id?.toString() ?? "",
      policyName: "DELETE_WORK_ORDER_COMMENT",
      userId: user?.id ?? 0,
    },
    {
      enabled: !!project && !!user?.id,
    }
  );

  const { mutate: deleteComment } = trpc.workOrders.deleteComment.useMutation({
    onSuccess: () => {
      toast.success("Comment Deleted Successfully");
      qc.invalidateQueries({
        predicate: (q) => (q.queryKey[0] as string).includes("comment"),
      });
    },
  });

  const handleDeleteComment = () => {
    if (comment) {
      deleteComment({
        commentId: comment.id,
      });
    }
    setIsDeleteDialogOpen(false);
  };

  const isCommentCreator = comment?.createdBy.id === user?.id;
  const canDeleteComment = policyCheck?.data === "ALLOWED";

  return (
    <div className="bg-neutral-100 rounded-xl p-4 flex flex-col gap-4">
      <div className="flex justify-between">
        <div className="flex gap-4">
          <Avatar className="bg-primary-900 w-12 h-12 font-bold">
            <AvatarFallback className="bg-transparent text-white">
              {comment?.createdBy.name?.substring(0, 2).toUpperCase() ?? "-"}
            </AvatarFallback>
          </Avatar>
          <div className="flex flex-col">
            <p className="font-sans font-bold">
              {comment?.createdBy.name ?? "-"}
            </p>
            <p className="font-sans text-neutral-500">
              {comment?.createdBy.position ?? "-"}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <p className="text-neutral-500">
            {diffDateTillNow({ compareISODate: comment?.createdOn ?? "" })}
          </p>
          {isCommentCreator && canDeleteComment && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon">
                  <MoreVertical className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={() => setIsDeleteDialogOpen(true)}>
                  <Trash className="mr-2 h-4 w-4" />
                  Delete
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </div>
      <p
        className={`font-sans ${
          comment?.commentType === "STATUS" ? `text-primary-900 font-bold` : ``
        }`}
      >
        {comment?.description
          ? comment.description.split("\n").map((line, i) => (
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            ))
          : "-"}
      </p>
      {comment?.attachments?.length !== 0 && (
        <div className="flex flex-wrap gap-x-2 gap-y-1">
          {comment?.attachments.map((att) => (
            <img
              key={att.id}
              src={att.url}
              className="w-24 h-24 rounded-xl object-cover hover:cursor-pointer"
              onClick={() => {
                window.open(att.url, "_blank");
              }}
            />
          ))}
        </div>
      )}
      {comment?.signatory?.length !== 0 && signatoryEnabled && (
        <div className="flex flex-wrap gap-x-2 gap-y-1">
          {comment?.signatory.map((sign) => (
            <div
              key={sign.id}
              className="border border-slate-300 rounded-xl gap-4 flex flex-col p-4"
            >
              <img
                src={sign.url}
                className="w-32 h-32 rounded-xl object-cover hover:cursor-pointer"
                onClick={() => {
                  window.open(sign.url, "_blank");
                }}
              />
              <div className="space-y-2">
                <p className="text-sm text-slate-500">Signed by</p>
                <p className="font-bold">{comment?.signedBy ?? "-"}</p>
              </div>
            </div>
          ))}
        </div>
      )}

      <DeleteCommentDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDeleteComment}
      />
    </div>
  );
}
