import { Loader2 } from "lucide-react";
import { useParams } from "react-router-dom";
import { useWorkRequestSettingControllerGetWorkRequestSettingsPublic } from "../../../api/work-request-setting/work-request-setting";
import AppLogoText from "../../../components/AppLogoText";
import PublicPageWrapper from "../../../components/PublicPageWrapper";
import PublicWorkRequestForm from "./components/PublicWorkRequestForm";
import { trpc } from "../../../lib/trpc";

export default function PublicWorkRequestFormScreen() {
  const { projectCode } = useParams();

  const { data: workRequestSetting, isLoading: workRequestSettingIsLoading } =
    trpc.workRequest.getWorkRequestSettingPublic.useQuery(
      {
        projectWorkRequestCode: projectCode ?? "",
      },
      {
        enabled: !!projectCode,
        select: (res) => res.data,
      }
    );

  if (workRequestSettingIsLoading) {
    return (
      <PublicPageWrapper className="justify-between items-center">
        <AppLogoText subtitle="Raise Work Request" className="w-full" />
        <div className="flex-1 flex flex-col justify-center items-center space-y-4">
          <Loader2 className="animate-spin text-primary-900 h-12 w-12" />
          <p className="text-slate-400">Loading...</p>
        </div>
      </PublicPageWrapper>
    );
  }

  return <PublicWorkRequestForm workRequestSetting={workRequestSetting} />;
}
