import { z } from "zod";

export const getWorkRequestQrCodeWithOptionalFieldSchema = z.object({
  projectId: z.string(),
  isLocationRequired: z.string(),
});

export type GetWorkRequestQrCodeWithOptionalField = z.infer<
  typeof getWorkRequestQrCodeWithOptionalFieldSchema
>;

export const getWorkRequestSettingPublicSchema = z.object({
  projectWorkRequestCode: z.string(),
});

export type GetWorkRequestSettingPublicInput = z.infer<
  typeof getWorkRequestSettingPublicSchema
>;

const visibilityEnum = z.enum(["HIDDEN", "VISIBLE"]);
const requiredEnum = z.enum(["REQUIRED", "NOT_REQUIRED"]);

export const updateWorkRequestSettingSchema = z.object({
  projectId: z.number(),
  emailRequired: requiredEnum.optional(),
  emailVisibility: visibilityEnum.optional(),
  subjectRequired: requiredEnum.optional(),
  subjectVisibility: visibilityEnum.optional(),
  descriptionRequired: requiredEnum.optional(),
  descriptionVisibility: visibilityEnum.optional(),
  contactNoRequired: requiredEnum.optional(),
  contactNoVisibility: visibilityEnum.optional(),
  locationRequired: requiredEnum.optional(),
  locationVisibility: visibilityEnum.optional(),
  locationDetailRequired: requiredEnum.optional(),
  locationDetailVisibility: visibilityEnum.optional(),
  nameRequired: requiredEnum.optional(),
  nameVisibility: visibilityEnum.optional(),
  departmentRequired: requiredEnum.optional(),
  departmentVisibility: visibilityEnum.optional(),
  photoRequired: requiredEnum.optional(),
  photoVisibility: visibilityEnum.optional(),
});

export type UpdateWorkRequestSettingInput = z.infer<
  typeof updateWorkRequestSettingSchema
>;

export const getOneWorkRequestSettingSchema = z.object({
  projectId: z.number(),
});

export type GetOneWorkRequestSettingInput = z.infer<
  typeof getOneWorkRequestSettingSchema
>;

export const getOneWorkRequestSchema = z.object({
  workRequestId: z.string(),
});

export type GetOneWorkRequestInput = z.infer<typeof getOneWorkRequestSchema>;
