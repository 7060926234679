import AppButton from "@/components/AppButton";
import AppTextAreaField from "@/components/AppTextAreaField";
import AppTextField from "@/components/AppTextField";
import { Rating } from "@mui/material";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Form, FormLabel } from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";
import { trpc } from "@/lib/trpc"; // Update import to use trpc
import { updateRatingReview } from "@cerev-cmms/trpc/src/api/workOrders/updateRatingReview/updateRatingReview.handler";
import {
  UpdateRatingReviewInput,
  updateRatingReviewSchema,
} from "@cerev-cmms/zod-types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { AlignLeft, Pen, Pencil, Star } from "lucide-react";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import AppImage from "../../../../../AppImage";
import useAppStorage from "../../../../../../hooks/useAppStorage";
import AppCameraFieldFile from "../../../../../AppCameraFieldFiles";
import { useAppSelector } from "../../../../../../redux/store";
import AppItemInfo from "../../../../../AppItemInfo";
import { AppRatings } from "../../../../../rating/AppRatings";

const formSchema = updateRatingReviewSchema
  .pick({
    workOrderId: true,
    rating: true,
    closingRemark: true,
    signedBy: true,
  })
  .extend({
    signatory: z.object({
      uploadedSignatory: z
        .object({
          name: z.string(),
          gsPath: z.string(),
        })
        .optional(),
      currentSignatory: z.instanceof(File).optional(),
    }),
  });

type FormType = z.infer<typeof formSchema>;

export default function VerificationSection() {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [searchParam] = useSearchParams();
  const workOrderId = searchParam.get("workOrderId");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const utils = trpc.useUtils();
  const qc = useQueryClient();

  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
  });

  const { data: workOrder } = trpc.workOrders.getOneWorkOrder.useQuery(
    { workOrderId: Number(workOrderId) ?? 0 },
    {
      enabled: !!workOrderId,
    }
  );

  useEffect(() => {
    if (!form.formState.isDirty && workOrder) {
      form.reset({
        workOrderId: workOrder.id,
        rating: Number(workOrder.rating),
        closingRemark: workOrder.closingRemark ?? "",
        signedBy: workOrder.closingSignedBy ?? "",
        ...(workOrder.closingSignatory
          ? {
              signatory: {
                uploadedSignatory: workOrder.closingSignatory,
              },
            }
          : {}),
      });
    }
  }, [workOrder]);

  const { mutateAsync: updateRatingReview } =
    trpc.workOrders.updateRatingReview.useMutation();

  const { useUploadAttachmentMutation } = useAppStorage();
  const { mutate: deleteVerificationSignatory } =
    trpc.workOrders.deleteVerificationSignatory.useMutation({
      onSuccess: () => {
        utils.workOrders.getOneWorkOrder.invalidate();
        qc.invalidateQueries({
          predicate: (q) =>
            (q.queryKey[0] as string).includes("defect") ||
            (q.queryKey[0] as string).includes("workOrder"),
        });
      },
    });
  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      setIsDialogOpen(false);
      form.reset();
      utils.workOrders.getOneWorkOrder.invalidate();
      qc.invalidateQueries({
        predicate: (q) =>
          (q.queryKey[0] as string).includes("defect") ||
          (q.queryKey[0] as string).includes("workOrder"),
      });
    },
  });

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    await mutate({
      files: data.signatory?.currentSignatory
        ? [data.signatory.currentSignatory]
        : [],
      mutateAsync: async (atts) => {
        const { signatory, ...updateData } = data;
        await updateRatingReview({
          ...updateData,
          ...(atts && atts.length > 0
            ? {
                signatory: {
                  ...atts[0],
                  projectId: Number(activeProj?.id),
                },
              }
            : {}),
        });
      },
    });
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <p className="text-xl font-bold">Verification</p>
        <AppButton
          variant="outline"
          onClick={() => setIsDialogOpen(true)}
          icon={<Pencil className="h-4 w-4" />}
          label="Update Verification"
        />
      </div>

      {/* Display current values */}
      <div className="space-y-6">
        <AppItemInfo
          label="Rating"
          icon={<Star className="text-primary-900" />}
          content={
            workOrder?.rating ? (
              <div>
                <AppRatings
                  rating={workOrder?.rating ? Number(workOrder?.rating) : 0}
                />
              </div>
            ) : (
              <>-</>
            )
          }
        />
        <AppItemInfo
          label="Closing Remark"
          icon={<AlignLeft />}
          content={<p>{workOrder?.closingRemark ?? "-"}</p>}
        />
        <AppItemInfo
          label="Closing Signatory"
          icon={<Pen />}
          content={
            workOrder?.closingSignatory ? (
              <AppImage
                att={workOrder.closingSignatory}
                className="max-w-xs rounded-md"
              />
            ) : (
              <p>-</p>
            )
          }
        />
        <AppItemInfo
          label="Signed By"
          icon={<Pen />}
          content={<p>{workOrder?.closingSignedBy ?? "-"}</p>}
        />
      </div>

      {/* Update Dialog */}
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="max-w-2xl overflow-y-scroll max-h-screen">
          <DialogHeader>
            <DialogTitle>Update Verification</DialogTitle>
          </DialogHeader>
          <Form {...form}>
            <div className="space-y-4">
              <AppTextAreaField
                label="Closing Remark"
                name="closingRemark"
                placeholder="Enter closing remarks..."
              />
              <div className="flex flex-col gap-2 mt-4">
                <FormLabel className="font-sans">Quality Rating</FormLabel>
                <Controller
                  defaultValue={5}
                  control={form.control}
                  name="rating"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <div className="flex">
                        <Rating
                          name="Quality Rating"
                          value={value}
                          size="large"
                          onChange={(event, newValue) => {
                            onChange(newValue);
                          }}
                        />
                      </div>
                    );
                  }}
                />
              </div>
              <Controller
                control={form.control}
                name="signatory"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <AppCameraFieldFile
                      onDeleteUploadedPhoto={async () => {
                        onChange({
                          ...value,
                          uploadedSignatory: undefined,
                        });
                        deleteVerificationSignatory({
                          workOrderId: Number(workOrderId),
                        });
                      }}
                      uploadedPhotos={
                        value?.uploadedSignatory
                          ? [value.uploadedSignatory]
                          : []
                      }
                      label="Signatory"
                      onChange={(files) => {
                        onChange({
                          ...value,
                          currentSignatory: files[0],
                        });
                      }}
                      onDelete={(url) => {
                        if (!value) return;
                        onChange({
                          ...value,
                          currentSignatory: undefined,
                        });
                      }}
                      photos={
                        value?.currentSignatory ? [value.currentSignatory] : []
                      }
                      error={!!error}
                      helperText={
                        form.formState.errors.signatory?.message !== ""
                          ? "At least one photo required"
                          : ""
                      }
                    />
                  );
                }}
              />
              <AppTextField
                label="Signed By"
                name="signedBy"
                placeholder="Enter signed by..."
              />
              <div className="flex justify-end gap-2">
                <AppButton
                  type="button"
                  variant="outline"
                  label="Cancel"
                  onClick={() => setIsDialogOpen(false)}
                />
                <AppButton
                  onClick={form.handleSubmit(onSubmit)}
                  label="Save"
                  isLoading={isPending}
                />
              </div>
            </div>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
