import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import AppButton from "@/components/AppButton";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import AppTextField from "@/components/AppTextField";

interface InstallationDateDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onUpdate: (value: Date) => void;
  currentValue?: string;
}

const schema = z.object({
  installationDate: z.string().refine((val) => !isNaN(Date.parse(val)), {
    message: "Invalid date format",
  }),
});

type FormValues = z.infer<typeof schema>;

export default function InstallationDateDialog({
  open,
  onOpenChange,
  onUpdate,
  currentValue,
}: InstallationDateDialogProps) {
  const methods = useForm<FormValues>({
    defaultValues: {
      installationDate: currentValue
        ? new Date(currentValue).toISOString().split("T")[0]
        : "",
    },
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    onUpdate(new Date(data.installationDate));
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Installation Date</DialogTitle>
        </DialogHeader>
        <FormProvider {...methods}>
          <AppTextField
            name="installationDate"
            label="Installation Date"
            type="date"
          />
        </FormProvider>
        <DialogFooter>
          <AppButton variant="ghost" onClick={() => onOpenChange(false)}>
            Cancel
          </AppButton>
          <AppButton onClick={methods.handleSubmit(onSubmit)}>Save</AppButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
