import { WorkRequestAvgRating } from "./WorkRequestAvgRating";
import WorkRequestDayTrendTurnaroundTime from "./WorkRequestDayTrendTurnaroundTime";
import { WorkRequestHistogram } from "./WorkRequestHistogram";
import WorkRequestKpiAvgTurnAroundTime from "./WorkRequestKpiAvgTurnAroundTime";
import { WorkRequestStatusBreakdownPieChart } from "./WorkRequestStatusBreakdownPieChart";
import WorkRequestStatusMonthlyBreakdown from "./WorkRequestStatusMonthlyBreakdown";
import WorkRequestTrend from "./WorkRequestTrend";

export function WorkRequestAnalytics() {
  return (
    <div className="grid grid-cols-2 gap-4">
      <WorkRequestTrend />
      <WorkRequestStatusBreakdownPieChart />
      <WorkRequestHistogram />
      <WorkRequestAvgRating />
      <div className="col-span-2">
        <WorkRequestStatusMonthlyBreakdown />
      </div>
      <div className="col-span-2">
        <WorkRequestKpiAvgTurnAroundTime />
      </div>
      <div className="col-span-2">
        <WorkRequestDayTrendTurnaroundTime />
      </div>
    </div>
  );
}
