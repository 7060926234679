import { z } from "zod";

export const jkrPataWorkOrder = z.object({
  refNo: z.string().nullable().optional(),
  workRequests: z
    .object({
      trackingCode: z.string(),
      email: z.string().nullable().optional(),
      name: z.string().nullable().optional(),
      title: z.string().nullable().optional(),
      description: z.string().nullable().optional(),
      contactNo: z.string().nullable().optional(),
      createdOn: z.string().nullable().optional(),
      dbLocation: z.object({
        name: z.string().nullable().optional(),
        code: z.string().nullable().optional(),
      }).nullable().optional(),
    })
    .array()
    .optional(),
  defectType: z.object({
    name: z.string().nullable().optional(),
  }).nullable().optional(),
  defectStatus: z.object({
    name: z.string().nullable().optional(),
  }).nullable().optional(),
  workPriority: z.object({
    name: z.string().nullable().optional(),
  }).nullable().optional(),
  createdOn: z.string().nullable().optional(),
  createdBy: z.object({
    name: z.string().nullable().optional(),
    position: z.string().nullable().optional(),
    createdOn: z.string().nullable().optional(),
  }).nullable().optional(),
  location: z.object({
    name: z.string().nullable().optional(),
    code: z.string().nullable().optional(),
  }).nullable().optional(),
  photos: z
    .object({
      url: z.string().nullable().optional(),
    })
    .array()
    .nullable().optional(),
  assignedUsers: z.object({
    name: z.string().nullable().optional(),
    position: z.string().nullable().optional(),
  }).array().nullable().optional(),
  comments: z.object({
    description: z.string().nullable().optional(),
    commentType: z.enum(["COMMENT", "STATUS"]).nullable().optional(),
    createdOn: z.string().nullable().optional(),
    attachments: z.object({
      url: z.string().nullable().optional(),
    }).array().nullable().optional(),
  }).array().nullable().optional(),
  completedOn: z.string().nullable().optional(),
  rootCause: z.string().nullable().optional(),
  correctiveAction: z.string().nullable().optional(),
});

export type JkrPataWorkOrder = z.infer<typeof jkrPataWorkOrder>;
