import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Line, LineChart, XAxis, YAxis } from "recharts";
import { trpc } from "../../../../../lib/trpc";
import { BarChart2 } from "lucide-react";
import { formatCurrency } from "../../../../../utils/formatters";
import { useAppSelector } from "../../../../../redux/store";
import { Skeleton } from "@/components/ui/skeleton";

interface MaintenanceCostChartProps {
  assetId: number;
}

export default function MaintenanceCostChart({
  assetId,
}: MaintenanceCostChartProps) {
  const project = useAppSelector((state) => state.root.activeProject);
  const { data: maintenanceCostData, isLoading } =
    trpc.assets.getEquipmentMaintenanceCost.useQuery(
      { equipmentId: assetId, projectId: project?.id ?? 0 },
      { enabled: !!assetId && !!project?.id }
    );

  if (isLoading) {
    return <SkeletonChart />;
  }

  return (
    <>
      <CardHeader>
        <CardTitle>Maintenance Cost</CardTitle>
        <CardDescription>Yearly maintenance cost</CardDescription>
      </CardHeader>
      <CardContent>
        {!maintenanceCostData || maintenanceCostData.chartData.length === 0 ? (
          <NoDataDisplay />
        ) : (
          <ChartContainer
            config={maintenanceCostData.chartConfig}
            className="h-[300px] w-full"
          >
            <LineChart
              data={maintenanceCostData.chartData}
              margin={{ top: 5, right: 16, left: 16, bottom: 5 }}
            >
              <XAxis dataKey="year" />
              <YAxis
                tickFormatter={formatCurrency}
                width={80}
                tickMargin={5}
                style={{ fontSize: "12px" }}
              />
              <ChartTooltip content={<ChartTooltipContent />} />
              <Line
                type="monotone"
                dataKey="yearlyCost"
                stroke={maintenanceCostData.chartConfig.yearlyCost?.color}
                strokeWidth={2}
                dot={false}
              />
            </LineChart>
          </ChartContainer>
        )}
      </CardContent>
    </>
  );
}

function NoDataDisplay() {
  return (
    <div className="flex flex-col items-center justify-center h-[300px]">
      <BarChart2 className="w-16 h-16 text-slate-400 mb-2" />
      <span className="text-slate-400">No Data</span>
    </div>
  );
}

function SkeletonChart() {
  return (
    <>
      <CardHeader>
        <Skeleton className="h-6 w-1/3 mb-2" />
        <Skeleton className="h-4 w-1/2" />
      </CardHeader>
      <CardContent>
        <Skeleton className="h-[300px] w-full" />
      </CardContent>
    </>
  );
}
