import { RouterOutputs } from "@cerev-cmms/trpc";
import { ColumnDef } from "@tanstack/react-table";

type AssetPPMData =
  RouterOutputs["analytics"]["getAssetPPMCompletionRate"]["chartData"][number];

export const columns: ColumnDef<AssetPPMData>[] = [
  {
    accessorKey: "location.name",
    header: "Location",
  },
  {
    accessorKey: "name",
    header: "Asset Name",
  },
  {
    header: "PPM Completion Rate",
    cell: ({ row }) => {
      const value = row.original.value;
      let bgColor = "bg-green-500 text-white";

      if (row.original.value <= 50) {
        bgColor = "bg-red-500 text-white";
      } else if (row.original.value <= 90) {
        bgColor = "bg-orange-500 text-white";
      }

      return (
        <div className={`rounded-full px-3 py-1 text-center w-fit ${bgColor}`}>
          <span className="font-medium">{value.toFixed(2)}%</span>
        </div>
      );
    },
  },
];
