import React from "react";
import { trpc } from "../../../../lib/trpc";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { useAppSelector } from "../../../../redux/store";

export function AssetPPMCompletionRate() {
  const project = useAppSelector((state) => state.root.activeProject);

  const { data, isLoading } = trpc.analytics.getAssetPPMCompletionRate.useQuery(
    {
      projectId: project?.id ?? 0,
    },
    {
      enabled: !!project,
    }
  );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!data || data.chartData.length === 0) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            No asset PPM completion rate data available
          </div>
        </CardContent>
      </Card>
    );
  }

  // Sort the data by completion rate in ascending order
  const sortedData = [...data.chartData].sort((a, b) => a.value - b.value);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Asset PPM Completion Rate</CardTitle>
        <CardDescription>
          Top 10 assets with the lowest PPM completion rates
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer
          config={data.chartConfig}
          className="mx-auto w-full h-[400px]"
        >
          <BarChart
            data={sortedData}
            layout="vertical"
            margin={{
              left: -20,
            }}
          >
            <XAxis
              type="number"
              domain={[0, 100]}
              unit="%"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
            />
            <YAxis
              dataKey="name"
              type="category"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Bar
              dataKey="value"
              fill={data.chartConfig.value.color}
              name={data.chartConfig.value.label}
              radius={5}
            />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
