import { PDFViewer } from "@react-pdf/renderer";
import { JkrApdSummaryPdf } from "@cerev-cmms/pdf";
import { useParams } from "react-router-dom";
import { trpc } from "../../lib/trpc";
import { useEffect, useMemo } from "react";
import { useAppSelector } from "../../redux/store";
import { useForm, useFieldArray } from "react-hook-form";
import { EditableCell } from "./components/EditableCell";

interface KPIRow {
  no: string;
  kpi: string;
  piNo: string;
  performanceIndicator: string;
  target: string;
  actual: string;
  demeritPoint: string;
  demeritPointsImposed: string;
  weightage: string;
  apdValue: string;
  apdValueDeducted: string;
}

interface FormValues {
  kpiRows: KPIRow[];
}

export function JkrApdSummaryPdfReview() {
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { data: workOrders } = trpc.workOrders.getWorkOrders.useQuery(
    {
      projectId: activeProj?.id?.toString(),
    },
    {
      enabled: !!activeProj,
    }
  );

  const kpiData: KPIRow[] = useMemo(() => [
    {
      no: "1",
      kpi: "DELIVERY RELATED TO CORE BUSINESS",
      piNo: "1A",
      performanceIndicator: "CUSTOMER SATISFACTION SURVEY RATING (CSS)",
      target: "80",
      actual: "83.31",
      demeritPoint: "1",
      demeritPointsImposed: "0",
      weightage: "6",
      apdValue: "423.66",
      apdValueDeducted: "0.00"
    },
    {
      no: "1",
      kpi: "",
      piNo: "1B",
      performanceIndicator: "CUSTOMER RATING IN WORK ORDER SHEET",
      target: "70",
      actual: "100",
      demeritPoint: "1",
      demeritPointsImposed: "0",
      weightage: "6",
      apdValue: "423.66",
      apdValueDeducted: "0.00"
    },
    {
      no: "1",
      kpi: "",
      piNo: "1C",
      performanceIndicator: "RESPONSE TIME",
      target: "100",
      actual: "100",
      demeritPoint: "1",
      demeritPointsImposed: "0",
      weightage: "7",
      apdValue: "494.27",
      apdValueDeducted: "0.00"
    },
    {
      no: "1",
      kpi: "",
      piNo: "1D",
      performanceIndicator: "EXECUTE TIME",
      target: "95",
      actual: "100",
      demeritPoint: "2",
      demeritPointsImposed: "0",
      weightage: "7",
      apdValue: "494.27",
      apdValueDeducted: "0.00"
    },
    {
      no: "1",
      kpi: "",
      piNo: "1E",
      performanceIndicator: "PENDING / BACKLOG WORK ORDER",
      target: "100",
      actual: "100",
      demeritPoint: "2",
      demeritPointsImposed: "0",
      weightage: "8",
      apdValue: "564.88",
      apdValueDeducted: "0.00"
    },
    {
      no: "1",
      kpi: "",
      piNo: "1F",
      performanceIndicator: "SELF-FINDING WORK ORDERS > 80% FROM TOTAL WORK ORDER",
      target: "80",
      actual: "99.22",
      demeritPoint: "2",
      demeritPointsImposed: "0",
      weightage: "10",
      apdValue: "706.10",
      apdValueDeducted: "0.00"
    },
    {
      no: "1",
      kpi: "",
      piNo: "1G",
      performanceIndicator: "CRITICAL SERVICES",
      target: "95",
      actual: "100",
      demeritPoint: "2",
      demeritPointsImposed: "0",
      weightage: "8",
      apdValue: "564.88",
      apdValueDeducted: "0.00"
    },
    {
      no: "1",
      kpi: "",
      piNo: "1H",
      performanceIndicator: "NORMAL SERVICES",
      target: "85",
      actual: "100",
      demeritPoint: "1",
      demeritPointsImposed: "0",
      weightage: "7",
      apdValue: "494.27",
      apdValueDeducted: "0.00"
    },
    {
      no: "2",
      kpi: "ASSET PERFORMANCE",
      piNo: "2A",
      performanceIndicator: "PPM FOR ARCHITECTURE AND CIVIL ASSETS IMPLEMENTED",
      target: "100",
      actual: "100",
      demeritPoint: "1",
      demeritPointsImposed: "0",
      weightage: "4",
      apdValue: "282.44",
      apdValueDeducted: "0.00"
    },
    {
      no: "2",
      kpi: "",
      piNo: "2B",
      performanceIndicator: "PPM FOR MECHANICAL ASSETS IMPLEMENTED",
      target: "100",
      actual: "100",
      demeritPoint: "1",
      demeritPointsImposed: "0",
      weightage: "4",
      apdValue: "282.44",
      apdValueDeducted: "0.00"
    },
    {
      no: "2",
      kpi: "",
      piNo: "2C",
      performanceIndicator: "PPM FOR ELECTRICAL ASSETS IMPLEMENTED",
      target: "100",
      actual: "100",
      demeritPoint: "1",
      demeritPointsImposed: "0",
      weightage: "4",
      apdValue: "282.44",
      apdValueDeducted: "0.00"
    },
    {
      no: "2",
      kpi: "",
      piNo: "2D",
      performanceIndicator: "ENGINEERING/SYSTEM REPORT & RECOMMENDATION ACTION TAKEN",
      target: "100",
      actual: "100",
      demeritPoint: "2",
      demeritPointsImposed: "0",
      weightage: "4",
      apdValue: "282.44",
      apdValueDeducted: "0.00"
    },
    {
      no: "2",
      kpi: "",
      piNo: "2E",
      performanceIndicator: "WORK DONE AS SPECIFICATION AND MANUFACTURER'S MERIT STANDARDS",
      target: "100",
      actual: "100",
      demeritPoint: "1",
      demeritPointsImposed: "0",
      weightage: "4",
      apdValue: "282.44",
      apdValueDeducted: "0.00"
    },
    {
      no: "3",
      kpi: "BUILDING ENERGY EFFICIENCY",
      piNo: "3A",
      performanceIndicator: "ENERGY CONSERVATION PROGRAMME IMPLEMENTED",
      target: "100",
      actual: "100",
      demeritPoint: "1",
      demeritPointsImposed: "0",
      weightage: "4",
      apdValue: "282.44",
      apdValueDeducted: "0.00"
    },
    {
      no: "3",
      kpi: "",
      piNo: "3B",
      performanceIndicator: "BUILDING ENERGY INDEX (BEI) TARGET MET",
      target: "100",
      actual: "100",
      demeritPoint: "1",
      demeritPointsImposed: "0",
      weightage: "3",
      apdValue: "211.83",
      apdValueDeducted: "0.00"
    },
    {
      no: "3",
      kpi: "",
      piNo: "3C",
      performanceIndicator: "UTILITY CONSUMPTION NO WASTAGE",
      target: "100",
      actual: "100",
      demeritPoint: "1",
      demeritPointsImposed: "0",
      weightage: "3",
      apdValue: "211.83",
      apdValueDeducted: "0.00"
    },
    {
      no: "4",
      kpi: "SAFETY & STATUTORY COMPLIANCE",
      piNo: "4A",
      performanceIndicator: "RELEVANT ACTS & REGULATIONS COMPLIANCE",
      target: "100",
      actual: "100",
      demeritPoint: "2",
      demeritPointsImposed: "0",
      weightage: "4",
      apdValue: "282.44",
      apdValueDeducted: "0.00"
    },
    {
      no: "4",
      kpi: "",
      piNo: "4B",
      performanceIndicator: "HSE PLAN IMPLEMENTED",
      target: "100",
      actual: "100",
      demeritPoint: "1",
      demeritPointsImposed: "0",
      weightage: "3",
      apdValue: "211.83",
      apdValueDeducted: "0.00"
    },
    {
      no: "4",
      kpi: "",
      piNo: "4C",
      performanceIndicator: "REPORTS SUBMITTED ON TIME WITH SUFFICIENT CONTENT",
      target: "100",
      actual: "100",
      demeritPoint: "1",
      demeritPointsImposed: "0",
      weightage: "4",
      apdValue: "282.44",
      apdValueDeducted: "0.00"
    }
  ], []);

  const { control, watch } = useForm<FormValues>({
    defaultValues: {
      kpiRows: kpiData
    }
  });

  const { fields } = useFieldArray({
    control,
    name: "kpiRows"
  });

  return (
    <div className="p-6">
      <div className="mb-6">
        <h1 className="text-2xl font-bold mb-2">Jun 2024</h1>
        <div className="grid grid-cols-2 gap-4 text-sm">
          <div>
            <p><span className="font-semibold">Period Date/Time:</span> dd.hh.2024 17:05:12</p>
            <p><span className="font-semibold">Verified By JKR:</span> 03 Jul 2024</p>
            <p><span className="font-semibold">Monthly Payment Value (MPV):</span> RM 70,609.58</p>
          </div>
          <div>
            <p><span className="font-semibold">Maximum APD (%):</span> 10</p>
            <p><span className="font-semibold">APD Max:</span> RM 7,060.96</p>
          </div>
        </div>
      </div>

      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="border p-2 text-sm">No.</th>
            <th className="border p-2 text-sm">Key Performance Indicators (KPI)</th>
            <th className="border p-2 text-sm">PI No.</th>
            <th className="border p-2 text-sm">Performance Indicators (PI)</th>
            <th className="border p-2 text-sm">Target (%)</th>
            <th className="border p-2 text-sm">Actual (%)</th>
            <th className="border p-2 text-sm">Demerit Point</th>
            <th className="border p-2 text-sm">Demerit Points Imposed</th>
            <th className="border p-2 text-sm">Weightage (%)</th>
            <th className="border p-2 text-sm">APD Value (RM)</th>
            <th className="border p-2 text-sm">APD Value Deducted (RM)</th>
          </tr>
        </thead>
        <tbody>
          {fields.reduce((acc: JSX.Element[], row, index, array) => {
            const prevRow = array[index - 1];
            const isNewSection = !prevRow || prevRow.no !== row.no;

            if (isNewSection) {
              const rowSpan = array.filter((r, i) => i >= index && r.no === row.no).length;
              
              acc.push(
                <tr key={row.id} className="hover:bg-gray-50">
                  <td rowSpan={rowSpan} className="border p-2 text-sm align-top">
                    {row.no}
                  </td>
                  <td rowSpan={rowSpan} className="border p-2 text-sm align-top">
                    {row.kpi}
                  </td>
                  <td className="border p-2 text-sm">{row.piNo}</td>
                  <td className="border p-2 text-sm">{row.performanceIndicator}</td>
                  <EditableCell index={index} fieldName="target" control={control} />
                  <EditableCell index={index} fieldName="actual" control={control} />
                  <EditableCell index={index} fieldName="demeritPoint" control={control} />
                  <EditableCell index={index} fieldName="demeritPointsImposed" control={control} />
                  <EditableCell index={index} fieldName="weightage" control={control} />
                  <EditableCell index={index} fieldName="apdValue" control={control} />
                  <EditableCell index={index} fieldName="apdValueDeducted" control={control} />
                </tr>
              );
            } else {
              acc.push(
                <tr key={row.id} className="hover:bg-gray-50">
                  <td className="border p-2 text-sm">{row.piNo}</td>
                  <td className="border p-2 text-sm">{row.performanceIndicator}</td>
                  <EditableCell index={index} fieldName="target" control={control} />
                  <EditableCell index={index} fieldName="actual" control={control} />
                  <EditableCell index={index} fieldName="demeritPoint" control={control} />
                  <EditableCell index={index} fieldName="demeritPointsImposed" control={control} />
                  <EditableCell index={index} fieldName="weightage" control={control} />
                  <EditableCell index={index} fieldName="apdValue" control={control} />
                  <EditableCell index={index} fieldName="apdValueDeducted" control={control} />
                </tr>
              );
            }
            return acc;
          }, [])}
          <tr className="font-bold bg-gray-100">
            <td colSpan={7} className="border p-2 text-sm">GRAND TOTAL</td>
            <td className="border p-2 text-sm text-right">25</td>
            <td className="border p-2 text-sm text-right">0</td>
            <td className="border p-2 text-sm text-right">100</td>
            <td className="border p-2 text-sm text-right">7,061.00</td>
            <td className="border p-2 text-sm text-right">0.00</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
