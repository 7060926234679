import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import AppTextField from "@/components/AppTextField";
import AppButton from "@/components/AppButton";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

interface PurchasePriceDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onUpdate: (value: number) => void;
  currentValue?: number;
}

export default function PurchasePriceDialog({ open, onOpenChange, onUpdate, currentValue }: PurchasePriceDialogProps) {
  const methods = useForm<{ purchasePrice: number }>({
    defaultValues: { purchasePrice: currentValue },
    resolver: zodResolver(z.object({ purchasePrice: z.coerce.number().min(0) })),
  });

  const onSubmit = (data: { purchasePrice: number }) => {
    onUpdate(data.purchasePrice);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Purchase Price</DialogTitle>
        </DialogHeader>
        <FormProvider {...methods}>
          <AppTextField
              name="purchasePrice"
              label="Purchase Price"
              type="number"
            step="0.01"
          />
        </FormProvider>
        <DialogFooter>
          <AppButton variant="ghost" onClick={() => onOpenChange(false)}>
                Cancel
              </AppButton>
          <AppButton onClick={methods.handleSubmit(onSubmit)}>Save</AppButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
