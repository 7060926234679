import { PDFViewer } from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import { JkrPataPdf } from "@cerev-cmms/pdf";
import { trpc } from "../../lib/trpc";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { AppCenterLoading } from "../../components/AppCenterLoading";
import { AppCenterError } from "../../components/AppCenterError";

export function JKRCustomWorkOrderPdfPreview() {
  const { workOrderId } = useParams();
  const { data, isLoading } = trpc.workOrders.getOneWorkOrder.useQuery(
    { workOrderId: Number(workOrderId) },
    {
      enabled: !!workOrderId,
      select: (res) => ({
        ...res,
        workRequests: res.workRequests?.map(wr => ({
          ...wr,
          createdOn: wr.createdOn
            ? format(
                utcToZonedTime(new Date(wr.createdOn), 'Asia/Singapore'),
                'd MMM yy, h:mm a'
              )
            : undefined
        })),
        createdOn: format(
          utcToZonedTime(new Date(res.createdOn), 'Asia/Singapore'),
          'd MMM yy, h:mm a'
        ),
        completedOn: res.completedOn 
          ? format(
              utcToZonedTime(new Date(res.completedOn), 'Asia/Singapore'),
              'd MMM yy, h:mm a'
            )
          : undefined,
        updatedOn: res.updatedOn
          ? format(
              utcToZonedTime(new Date(res.updatedOn), 'Asia/Singapore'),
              'd MMM yy, h:mm a'
            )
          : undefined,
        createdBy: {
          ...res.createdBy,
          createdOn: res.createdBy?.createdOn 
            ? format(
                utcToZonedTime(new Date(res.createdBy.createdOn), 'Asia/Singapore'),
                'd MMM yy, h:mm a'
              )
            : undefined
        },
        stockLedgers: res.stockLedgers?.map(ledger => ({
          ...ledger,
          createdOn: format(
            utcToZonedTime(new Date(ledger.createdOn), 'Asia/Singapore'),
            'd MMM yy, h:mm a'
          )
        }))
      })
    }
  );

  if (isLoading) return <AppCenterLoading />;

  if (!data) return <AppCenterError />;

  return (
    <>
      <PDFViewer style={{ height: "100vh", width: "100vw" }}>
        <JkrPataPdf workOrder={data} />
      </PDFViewer>
    </>
  );
}
