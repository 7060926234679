import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import { useAssetControllerGetFullAssets } from "../../../../api/assets/assets";
import { useAuthControllerGetCurrentUser } from "../../../../api/auth/auth";
import { useDefectTypeControllerGetDefectTypes } from "../../../../api/defect-type/defect-type";
import { useUserControllerGetUsers } from "../../../../api/users/users";
import { useDefectControllerCreateDefect } from "../../../../api/work-orders-defects/work-orders-defects";
import {
  useWorkRequestControllerGetOneWorkRequest,
  useWorkRequestControllerGetWorkRequests,
} from "../../../../api/work-request/work-request";
import useAppStorage from "../../../../hooks/useAppStorage";
import { useGetUserDataQuery } from "../../../../redux/slices/Auth/AuthApi";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import AppButton from "../../../AppButton";
import AppCameraFieldFile from "../../../AppCameraFieldFiles";
import AppTextAreaField from "../../../AppTextAreaField";
import AppTextField from "../../../AppTextField";
import AppSelectWithDialog from "../../../dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { assetColumns } from "../columns/assetColumns";
import { assignedUserColumns } from "../columns/assignedUserColumns";
import { linkWorkRequestColumns } from "../columns/linkWorkRequestColumns";
import { workOrderTypeColumns } from "../columns/workOrderTypeColumns";
import AppDocumentUpload from "../../../AppDocumentUpload";
import { useEffect } from "react";
import WOAttachmentField from "./WOAttachmentField";
import { trpc } from "../../../../lib/trpc"; // Import trpc

const BaseWorkOrderFormSchema = z.object({
  workOrderType: z.object(
    {
      id: z.number(),
      name: z.string(),
    },
    {
      required_error: "Please select a work order type",
    }
  ),
  asset: z
    .object({
      id: z.number(),
      name: z.string(),
      locationId: z.number(),
    })
    .optional(),
  subject: z.string().min(1, { message: "Please insert a subject" }),
  description: z.string().min(1, { message: "Please insert a description" }),
  assignedUser: z
    .object({
      id: z.number(),
      name: z.string(),
      vendorId: z.number().nullable(),
    })
    .array()
    .min(1, { message: "Please select a user" }),
  attachments: z.instanceof(File).array(),
  docs: z.instanceof(File).array(),
  linkedWRAttachments: z
    .object({ id: z.number(), url: z.string() })
    .array()
    .optional(),
  workRequests: z
    .object({
      id: z.string(),
    })
    .array()
    .optional(),
});

const AttachmentSchemas = z.object({
  linkedWRAttachments: z
    .object({ id: z.number(), url: z.string() })
    .array()
    .optional(),
  attachments: z.instanceof(File).array(),
});
// .refine(
//   (schema) => {
//     if (schema.linkedWRAttachments && schema.linkedWRAttachments.length > 0) {
//       return true;
//     }
//     return schema.attachments ? schema.attachments.length > 0 : false;
//   },
//   {
//     path: ["attachments"],
//     message: "At least one photo is required",
//   }
// );

const WorkOrderFormSchema = z.intersection(
  BaseWorkOrderFormSchema,
  AttachmentSchemas
);

export type WorkOrderForm = z.infer<typeof WorkOrderFormSchema>;

export default function WorkOrderFormDrawer() {
  const utils = trpc.useUtils();
  const methods = useForm<WorkOrderForm>({
    resolver: zodResolver(WorkOrderFormSchema),
    defaultValues: {
      workOrderType: undefined,
      asset: undefined,
      subject: "",
      description: "",
      assignedUser: [],
      attachments: [],
      workRequests: [],
      docs: [],
    },
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const workRequestId = searchParams.get("workRequestId"); // To carry forward title, description and photo from work request
  const { data: user } = useGetUserDataQuery();
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const qc = useQueryClient();

  const { data: workRequest } = useWorkRequestControllerGetOneWorkRequest(
    workRequestId ?? "",
    {
      query: {
        enabled: !!workRequestId,
        select: (res) => res.data,
      },
    }
  );

  const { data: workOrderTypeList } = useDefectTypeControllerGetDefectTypes(
    {
      projectId: activeProj?.id ?? 0,
      companyId: activeComp?.id ?? 0,
    },
    {
      query: {
        enabled: !!activeComp && !!activeProj,
      },
    }
  );

  const { data: fullAssetList } = useAssetControllerGetFullAssets(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj,
      },
    }
  );

  const {
    data: currentUser,
    isError: currentUserIsError,
    error: currentUserError,
  } = useAuthControllerGetCurrentUser();

  const {
    data: usersData,
    isError: usersDataIsError,
    error: usersDataError,
  } = useUserControllerGetUsers(
    {
      projectId: activeProj?.id ?? 0,
      userId: currentUser?.data?.id ?? 0,
      companyId: activeComp?.id ?? 0,
    },
    {
      query: {
        enabled: !!activeProj && !!currentUser && !!activeComp,
      },
    }
  );

  const {
    data: workRequestList,
    isError: workRequestListIsError,
    error: workRequestListError,
  } = useWorkRequestControllerGetWorkRequests(
    {
      showUnlinkedOnly: true,
      workRequestStatus: "NEW",
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj,
      },
    }
  );

  const dispatch = useAppDispatch();
  const { mutateAsync: createWorkOrder } =
    trpc.workOrders.createWorkOrder.useMutation(); // Use trpc mutation
  const { useUploadAttachmentMutation } = useAppStorage();

  // Update to work request title, description and photos if workRequestid exist - Means the create command comes from work request detail screen
  useEffect(() => {
    if (workRequest) {
      methods.setValue("subject", workRequest?.title ?? "");
      methods.setValue("description", workRequest?.description ?? "");
      methods.setValue(
        "linkedWRAttachments",
        workRequest.photos.map((p) => ({ id: p.id, url: p.url }))
      );
      methods.setValue("workRequests", [{ id: workRequest.id }]);
      if (
        workRequest.asset !== undefined &&
        workRequest.asset?.locationId !== undefined
      )
        methods.setValue("asset", {
          id: workRequest.asset.id,
          name: workRequest.asset.name,
          locationId: workRequest.asset.locationId,
        });
    }
  }, [workRequest]);

  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      utils.workRequest.getOneWorkRequest.invalidate();
      qc.invalidateQueries({
        predicate: (query) => {
          return (
            (query.queryKey[0] as string).includes("work-order") ||
            (query.queryKey[0] as string).includes("defect") ||
            (query.queryKey[0] as string).includes("work-request")
          );
        },
      });
      setSearchParams((p) => {
        p.delete("drawerType");
        p.delete("drawerState");
        return { ...p };
      });
    },
  });

  const onSubmit: SubmitHandler<WorkOrderForm> = async (data) => {
    await mutate({
      files: data.attachments ?? [],
      secondFiles: data.docs ?? [],
      mutateAsync: async (atts, docs) => {
        await createWorkOrder({
          projectId: activeProj?.id ?? 0,
          locationId: data.asset?.locationId,
          assetId: data.asset?.id,
          subject: data.subject,
          description: data.description,
          photos: atts?.map((a) => ({
            name: a?.name ?? "",
            fileSizeMb: a?.fileSizeMb ?? "",
            underProject: { id: activeProj?.id! },
            underProjectId: activeProj?.id!,
            gsPath: a?.gsPath ?? "",
            url: a?.url ?? "",
          })),
          docs: docs?.map((a) => ({
            name: a?.name ?? "",
            fileSizeMb: a?.fileSizeMb ?? "",
            underProject: { id: activeProj?.id! },
            underProjectId: activeProj?.id!,
            gsPath: a?.gsPath ?? "",
            url: a?.url ?? "",
          })),
          defectTypeId: data.workOrderType.id,
          assignedUsersIds: data.assignedUser.map((usr) => usr.id),
          assignedVendorsIds: data.assignedUser.reduce(
            (prev: number[], curr: { vendorId: number | null }) => {
              if (curr.vendorId) prev.push(curr.vendorId);
              return prev;
            },
            []
          ),
          workRequestIds: data.workRequests?.map((wr) => wr.id) ?? [],
          linkWRPhotosIds: data.linkedWRAttachments?.map((p) => p.id) ?? [],
        });
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">Create Work Order</p>
        <AppSelectWithDialog
          columns={workOrderTypeColumns}
          label="Work Order Type"
          placeholder="Select Work Order Type"
          defaultValue={undefined}
          control={methods.control}
          name="workOrderType"
          items={workOrderTypeList?.data ?? []}
          onResultRender={(item, idx) => {
            return <div key={idx}>{item?.name}</div>;
          }}
          onOptionsRender={(item, idx) => <div key={idx}>{item?.name}</div>}
          dialogTitle="Select Work Order Type"
          error={!!methods.formState.errors.workOrderType?.message}
          helperText={methods.formState.errors.workOrderType?.message}
        />
        <AppSelectWithDialog
          label="Equipment"
          placeholder="Select an Equipment"
          columns={assetColumns}
          defaultValue={undefined}
          control={methods.control}
          name="asset"
          items={fullAssetList?.data ?? []}
          onResultRender={(item, idx) => (
            <div key={idx} className="flex flex-col">
              <div className="font-medium">{item?.name}</div>
              <div className="font-thin mt-1">
                {item?.assetType?.name ?? "-"}
              </div>
            </div>
          )}
          onOptionsRender={(item, idx) => (
            <div key={idx} className="flex flex-col">
              <div className="font-medium">{item?.name}</div>
              <div className="font-thin mt-1">
                {item?.assetType?.name ?? "-"}
              </div>
            </div>
          )}
          dialogTitle="Select an Equipment"
          error={!!methods.formState.errors.asset?.message}
          helperText={methods.formState.errors.asset?.message}
        />
        <AppTextField label="Subject" name="subject" />
        <AppTextAreaField label="Description" name="description" />
        <AppSelectWithDialog
          columns={assignedUserColumns}
          label="Assign To"
          placeholder="Select Users"
          defaultValue={[]}
          control={methods.control}
          name="assignedUser"
          multiple
          items={usersData?.data ?? []}
          onResultRender={(item, idx) => (
            <div key={idx} className="flex flex-col">
              <div className="font-medium">{item?.name}</div>
              <div className="font-thin mt-1">{item?.position ?? "-"}</div>
            </div>
          )}
          onOptionsRender={(item, idx) => (
            <div key={idx} className="flex flex-col">
              <div className="font-medium">{item?.name}</div>
              <div className="font-thin mt-1">{item?.position ?? "-"}</div>
            </div>
          )}
          dialogTitle="Select Users"
          error={!!methods.formState.errors.assignedUser?.message}
          helperText={methods.formState.errors.assignedUser?.message}
        />
        <WOAttachmentField />
        <Controller
          control={methods.control}
          name="docs"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AppDocumentUpload
                label="Videos or Documents"
                onChange={onChange}
                onDelete={(url) => {
                  if (!value) return;
                  const newFiles = value.filter((v) => v !== url);
                  onChange(newFiles);
                }}
                files={value ?? []}
                error={!!error}
                helperText={
                  methods.formState.errors.docs?.message !== ""
                    ? methods.formState.errors.docs?.message
                    : ""
                }
              />
            );
          }}
        />
        <AppSelectWithDialog
          multiple
          columns={linkWorkRequestColumns}
          label="Work Requests"
          placeholder="Link Work Requests"
          defaultValue={[]}
          control={methods.control}
          name="workRequests"
          items={workRequestList?.data ?? []}
          onResultRender={(item, idx) => (
            <div className="flex flex-col">
              <div className="font-medium">{item?.title}</div>
              <div className="font-thin mt-1 truncate">{item?.description}</div>
            </div>
          )}
          onOptionsRender={(item, idx) => (
            <div className="flex flex-col">
              <div className="font-medium">{item?.title}</div>
              <div className="font-thin mt-1 truncate">{item?.description}</div>
            </div>
          )}
          dialogTitle="Select Work Requests"
          error={!!methods.formState.errors.workRequests?.message}
          helperText={methods.formState.errors.workRequests?.message}
        />
        <div className="flex">
          <AppButton
            isLoading={isPending}
            onClick={methods.handleSubmit(onSubmit)}
            label="Submit"
          />
        </div>
      </div>
    </FormProvider>
  );
}
