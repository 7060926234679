import * as React from "react";
import { Document, Font, renderToFile } from "@react-pdf/renderer";
import { JkrPataWorkOrder } from "@cerev-cmms/zod-types";
import { PataPage } from "./components/PataPage";
import { PataWorkOrderPage } from "./components/PataWorkOrderPage";

Font.register({
  family: "Fira Sans",
  fonts: [
    {
      src: "https://storage.googleapis.com/cerev-dev.appspot.com/public-fonts/FiraSans-Regular.ttf",
      fontStyle: "normal",
      fontWeight: "normal",
    },
    {
      src: "https://storage.googleapis.com/cerev-dev.appspot.com/public-fonts/FiraSans-Bold.ttf",
      fontStyle: "normal",
      fontWeight: "bold",
    },
  ],
});

interface JkrPataPdfProps {
  workOrder?: JkrPataWorkOrder;
}

const JkrPataWorkOrderContext = React.createContext<JkrPataWorkOrder>(
  {} as any
);

export const useJkrPataWorkOrder = () =>
  React.useContext<JkrPataWorkOrder>(JkrPataWorkOrderContext);

export function JkrPataPdf({ workOrder }: JkrPataPdfProps) {
  return (
    <JkrPataWorkOrderContext.Provider
      value={
        workOrder ??
        ({
          refNo: "KDNP-0001",
          workRequests: [
            {
              trackingCode: "KDNP-0001",
              email: "john.doe@example.com",
              name: "John Doe",
              title: "Aircon Leaking",
              description: "Selalu aircon ada problem",
              contactNo: "0123456789",
              createdOn: new Date().toISOString(),
              dbLocation: {
                name: "JPN",
                code: "JPB-L3-1",
              },
            },
          ],
          defectType: {
            name: "Self Finding",
          },
          createdOn: new Date().toISOString(),
          createdBy: {
            name: "Joseph",
            position: "IT Support",
            createdOn: new Date().toISOString(),
          },
          photos: [],
          location: {
            name: "JPN Work Order",
            code: "JPB-L3-1-WO",
          },
          defectStatus: {
            name: "Pending",
          },
          workPriority: {
            name: "High",
          },
          assignedUsers: [
            {
              name: "Joseph",
              position: "IT Support",
            },
            {
              name: "Amy",
              position: "IT Support",
            },
          ],
          comments: [
            {
              description: "Sedang checking",
              commentType: "COMMENT",
              createdOn: new Date().toISOString(),
            },
            {
              description: "Sudah order parts dari contractor sekarang",
              commentType: "COMMENT",
              createdOn: new Date().toISOString(),
              attachments: [
                {
                  url: "https://www.shutterstock.com/image-photo/professional-mechanic-working-on-engine-260nw-2470026155.jpg",
                },
              ],
            },
          ],
          completedOn: new Date().toISOString(),
        } as JkrPataWorkOrder)
      }
    >
      <Document style={{ fontFamily: "Fira Sans" }}>
        {workOrder?.workRequests?.length &&
          workOrder?.workRequests?.length > 0 && <PataPage />}
        <PataWorkOrderPage />
      </Document>
    </JkrPataWorkOrderContext.Provider>
  );
}

export async function renderJkrPataPdf({
  workOrder,
  filePath,
}: {
  workOrder: JkrPataWorkOrder;
  filePath: string;
}) {
  return await renderToFile(<JkrPataPdf workOrder={workOrder} />, filePath);
}
