import * as React from "react";
import { Text, View } from "@react-pdf/renderer";
import { useJkrPataWorkOrder } from "..";

export function FooterSection() {
  const workOrder = useJkrPataWorkOrder();

  return (
    <View
      style={{
        marginTop: 12,
        borderLeft: "1px solid black",
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
      }}
      wrap={false}
    >
      <View style={{ flexDirection: "row", borderTop: "1px solid black" }}>
        <View style={{ flex: 1, borderRight: "1px solid black", padding: 4 }}>
          <Text style={{ fontSize: 9 }}>Nama Penerima</Text>
        </View>
        <View style={{ flex: 2, padding: 4 }}>
          <Text style={{ fontSize: 9 }}>{workOrder.createdBy?.name}</Text>
        </View>
      </View>
      <View style={{ flexDirection: "row", borderTop: "1px solid black" }}>
        <View style={{ flex: 1, borderRight: "1px solid black", padding: 4 }}>
          <Text style={{ fontSize: 9 }}>Jawatan</Text>
        </View>
        <View style={{ flex: 2, padding: 4 }}>
          <Text style={{ fontSize: 9 }}>{workOrder.createdBy?.position}</Text>
        </View>
      </View>
      <View style={{ flexDirection: "row", borderTop: "1px solid black" }}>
        <View style={{ flex: 1, borderRight: "1px solid black", padding: 4 }}>
          <Text style={{ fontSize: 9 }}>Tarikh / Masa</Text>
        </View>
        <View style={{ flex: 2, padding: 4 }}>
          <Text style={{ fontSize: 9 }}>{workOrder.createdBy?.createdOn}</Text>
        </View>
      </View>
    </View>
  );
}
